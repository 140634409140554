import { Button, CustomImage } from "@veneer/core"
import React from "react"
import Images from "../../assets/images"
import { CheckMark } from "./styles"
import { i18n } from "../../assets/I18n"

type ButtonEnableScanDestinationsProps = {
  onClick: { (): void }
  loading: boolean
  isEnable: boolean
}

const ButtonEnableScanDestinations: React.FC<ButtonEnableScanDestinationsProps> = ({
  onClick,
  loading,
  isEnable,
}) => {
  if (!isEnable) {
    return (
      <>
        <Button
          id="enable-scan-destinations"
          loading={loading}
          onClick={onClick}
          customStyle={{
            width: "104px",
            height: "36px",
            borderRadius: "8px",
            webkitAlignSelf: "center",
            msFlexItemAlign: "center",
            alignSelf: "center",
          }}
        >
          {i18n.assetsProvider.getText("common.enable")}
        </Button>
      </>
    )
  } else {
    return (
      <CheckMark>
        <CustomImage
          id="check-mark-circle"
          src={Images.checkMarkCircle}
          size={24}
        />
        {i18n.assetsProvider.getText("common.enabled")}
      </CheckMark>
    )
  }
}

export default ButtonEnableScanDestinations
