import { Search } from "@veneer/core"
import React from "react"
import { SearchContainer, SearchForm } from "./styles"

type CustomSearchProps = {
  searchLabel: string
  onChange: { (value: string): void }
  onClear: { (): void }
  onSubmitHandler: { (): void }
  isFullBar?: boolean
}

const CustomSearch = ({
  searchLabel,
  onChange,
  onClear,
  onSubmitHandler,
  isFullBar,
}: CustomSearchProps) => {
  const handlerSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    onSubmitHandler()
  }
  return (
    <SearchContainer id="search-container" isFullBar={isFullBar}>
      <SearchForm
        id="search-form-container"
        role="form"
        onSubmit={handlerSubmit}
      >
        <Search
          id="search-content"
          label={searchLabel}
          onChange={onChange}
          onClear={onClear}
        />
      </SearchForm>
    </SearchContainer>
  )
}

export default CustomSearch
