import { useEffect, useState } from "react"

export type UsePollingProps = {
  timeout: number
  callBack: () => Promise<any>
  //This props define the interval time in seconds of each request. For example, if this props is set to 15s after the first request a new one will be trigged only after 15s and it will goes on until the timeout is reached
  intervalTime?: number
}

const usePolling = ({ timeout, callBack, intervalTime }: UsePollingProps) => {
  const [time, setTime] = useState(0)
  const [finalResult, setFinalResult] = useState(null)
  const [isFinished, setIsFinished] = useState(false)
  const [stopPolling, setStopPolling] = useState(false)

  const elapsedTimeInSeconds = (initialTime: Date, endTime: Date) => {
    const diff = endTime.getTime() - initialTime.getTime()
    return Math.round(diff / 1000)
  }

  useEffect(() => {
    //To convert the intervalTime into seconds
    const intervalTimeTimeout = intervalTime ? intervalTime * 1000 : 15000
    const makeRequest = async () => {
      const initialTime = new Date()
      try {
        const result = await callBack()

        setFinalResult(result)
        setStopPolling(true)
        setIsFinished(true)
      } catch (error) {
        console.error(error)
      } finally {
        const endTime = new Date()
        const resultTime =
          time + elapsedTimeInSeconds(initialTime, endTime) + intervalTime
        setTime(resultTime)
      }
    }

    if (timeout && time < timeout && !stopPolling) {
      setTimeout(() => {
        makeRequest()
      }, intervalTimeTimeout)
      return
    }

    if (!isFinished && time >= timeout) {
      setIsFinished(true)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [time, isFinished, stopPolling])

  return {
    finalResult,
    stopPolling,
    isFinished,
    setStopPolling,
  }
}

export default usePolling
