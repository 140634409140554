import {
  AnalyticsCDM,
  CdmEventOptions,
  DataValveCDMEvent,
} from "../../mfe/types/shell"

export const createSendEvent = (
  analytics: AnalyticsCDM,
  cdmEventOptions: CdmEventOptions
) => {
  const sendUiEvent = (uiData: object) => {
    const dataValveCDMEvent: DataValveCDMEvent = {
      dateTime: new Date().toISOString(),
      eventDetailType:
        "com.hp.cdm.domain.telemetry.type.eventDetail.category.simpleUi.version.1",
      eventCategory: "simpleUi",
      version: "1.4.0",
      eventDetail: {
        ...uiData,
        ...{ activity: cdmEventOptions.activity },
      },
    }

    try {
      if (analytics?.publishCdmEvents) {
        analytics.publishCdmEvents([dataValveCDMEvent], cdmEventOptions.options)
      }
    } catch (error) {
      console.error("Error while sending analytics event for Scan Destinations")
    }
  }

  return sendUiEvent
}

const defaultCDMData = {
  screenPath: "/ReactSmbScanDestinations/",
  version: "1.1.0",
}

export const uiData = {
  BasicSettings: {
    screenBasicSettings: {
      action: "ScreenDisplayed",
      screenName: "ScanSetUp",
      ...defaultCDMData,
    },
    btnSetUp: {
      action: "ControlButtonClicked",
      screenName: "ScanSetUp",
      ...defaultCDMData,
    },
    enableScanDestToggle: {
      action: "ControlToggleEnabled",
      controlName: "ScanDestinationToggle",
      screenName: "PrinterDetails",
      screenMode: "Overview",
      ...defaultCDMData,
    },
    disableScanDestToggle: {
      action: "ControlToggleDisabled",
      controlName: "ScanDestinationToggle",
      screenName: "PrinterDetails",
      screenMode: "Overview",
      ...defaultCDMData,
    },
    createScanToEmailTemplate: {
      action: "ControlButtonClicked",
      controlName: "CreateScanToEmailTemplateButton",
      screenName: "PrinterDetails",
      screenMode: "Overview",
      ...defaultCDMData,
    },
  },
  CreationForm: {
    shortcutNameText: {
      action: "ControlButtonClicked",
      controlName: "ShortcutNameText",
      ...defaultCDMData,
    },
    selectFileTypeDropDown: {
      action: "ControlButtonClicked",
      controlName: "SelectFileTypeDropDown",
      ...defaultCDMData,
    },
    toggleEnableSecurityPin: {
      action: "ControlToggleEnabled",
      controlName: "SecurityPinToggle",
      ...defaultCDMData,
    },
    toggleDisableSecurityPin: {
      action: "ControlToggleDisabled",
      controlName: "SecurityPinToggle",
      ...defaultCDMData,
    },
    btnShortcutFormCancel: {
      action: "ControlButtonClicked",
      controlName: "CancelButton",
      ...defaultCDMData,
    },
    btnShortcutFormSave: {
      action: "ControlButtonClicked",
      controlName: "SaveButton",
      ...defaultCDMData,
    },
    btnShortcutOverlay: {
      action: "ScreenDisplayed",
      ...defaultCDMData,
    },
    btnShortcutOverlayCancel: {
      action: "ControlButtonClicked",
      controlName: "CancelButton",
      ...defaultCDMData,
    },
    btnShortcutOverlayLeave: {
      action: "ControlButtonClicked",
      controlName: "LeaveButton",
      ...defaultCDMData,
    },
    screenScanEmail: {
      action: "ScreenDisplayed",
      screenName: "ScanEmailSetUp",
      ...defaultCDMData,
    },
    editEmailInfoText: {
      action: "ControlButtonClicked",
      controlName: "EditEmailInfoText",
      screenName: "ScanEmailSetUp",
      ...defaultCDMData,
    },
    screenScanCloud: {
      action: "ScreenDisplayed",
      screenName: "ScanCloudSetUp",
      ...defaultCDMData,
    },
    selectCloudDestinationDropDown: {
      action: "ControlButtonClicked",
      controlName: "SelectCloudDestinationDropDown",
      screenName: "ScanCloudSetUp",
      ...defaultCDMData,
    },
    errorToastSave: {
      action: "ScreenDisplayed",
      ...defaultCDMData,
    },
    successToastSave: {
      action: "ScreenDisplayed",
      ...defaultCDMData,
    },
  },
  ScanDestinationsTabs: {
    screenScanDestTabs: {
      action: "ScreenDisplayed",
      screenName: "ScanManage",
      ...defaultCDMData,
    },
    btnCreateNew: {
      action: "ControlButtonClicked",
      controlName: "CreateNewButton",
      screenName: "ScanManage",
      ...defaultCDMData,
    },
  },
}
