import { Card, Select } from "@veneer/core"
import tokens from "@veneer/tokens"
import styled from "styled-components"

export const MainContainer = styled(Card)`
  display: grid;
  grid-template-rows: auto auto;
  box-shadow: 0px 1px 4px 1px rgba(33, 33, 33, 0.2);

  #file-type-form-container {
    @media (max-width: ${tokens.mdMin}) {
      display: flex;
      flex-direction: column;
    }
  }

  #security-pin-input-container {
    @media (max-width: ${tokens.mdMin}) {
      width: auto;
    }
  }

  #file-type-content-container {
    width: 100%;
    @media (max-width: ${tokens.mdMin}) {
      width: auto !important;
    }
  }
`
export const SettingsTitle = styled.h6`
  height: 56px;
  display: flex;
  align-items: center;
  -webkit-box-sizing: border-box !important;
  -moz-box-sizing: border-box !important;
  box-sizing: border-box !important;
  padding-left: 16px;
  border-bottom: 1px solid ${tokens.colorGray2};
  @media (max-width: ${tokens.smMin}) {
    padding-top: 8px;
    padding-bottom: 8px;
  }
`

export const FileTypeContainer = styled.div`
  padding: 24px;
`

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
`

export const FileTypeTitle = styled.h6`
  padding-bottom: 24px;
  @media (max-width: ${tokens.smMin}) {
    padding-top: 8px;
    padding-bottom: 8px;
  }
`

export const FileNameTitle = styled.h6`
  padding-bottom: 24px;
  @media (max-width: ${tokens.smMin}) {
    padding-top: 8px;
    padding-bottom: 8px;
  }
`

export const FileTypeSelect = styled(Select)`
  -webkit-box-sizing: border-box !important;
  -moz-box-sizing: border-box !important;
  box-sizing: border-box !important;
  width: 100% !important;
`

export const ContentLanguageSelect = styled(Select)`
  padding-top: 24px;
  padding-bottom: 32px;
  -webkit-box-sizing: border-box !important;
  -moz-box-sizing: border-box !important;
  box-sizing: border-box !important;
  width: 100% !important;
`

export const FileNameContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const FileNameTextContainer = styled.div``

export const FileNameHeader = styled.div`
  padding: 8px 0px 0px 36px;
`
