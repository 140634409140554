import { JarvisAuthProvider } from "@jarvis/web-http"
import { useCallback, useState } from "react"
import { FolderItem, FolderStructure } from "../../data/schemas/folders"
import {
  mapTreeData,
  searchNode,
  updateASingleFolder,
} from "../../helpers/treeViewMapping"
import useTenantAPI from "../api/useTenantApi"

export type UseTreeViewProps = {
  stack: number
  authProvider: JarvisAuthProvider
}

const useTreeViewNodes = ({ stack, authProvider }: UseTreeViewProps) => {
  const [isLoadingFolders, setFoldersLoading] = useState(false)
  const [folderTree, setTree] = useState<FolderItem[]>([])
  const [errorOnFetchFolder, setErrorOnFetchFolder] = useState(null)
  const { getConnectorFolders } = useTenantAPI(stack, authProvider)

  const mapNewNode = useCallback(
    (folder: FolderStructure) => {
      const characters = "%21"
      const symbol = "!"

      // TODO interim measure until Tenzing modifies return of OneDrive related ID in child folders
      if (folder.parent.id.includes(characters)) {
        folder.parent.id = folder.parent.id.replace(characters, symbol)
      }

      setTree((previousTree) => {
        return !folderTree.length
          ? [
              {
                ...folder.parent,
                loaded: true,
                data: folder.data,
              },
            ]
          : mapTreeData(previousTree, folder)
      })
    },
    [folderTree]
  )

  const getFoldersByParentId = useCallback(
    async (parentId: string, showLoader: boolean, connectorName = "") => {
      try {
        setFoldersLoading(showLoader)
        const folderNode = await getConnectorFolders(parentId)

        if (connectorName) {
          folderNode.parent.name = connectorName
        }

        mapNewNode(folderNode)
        setErrorOnFetchFolder(null)
      } catch (error: any) {
        setErrorOnFetchFolder(error)
      } finally {
        setFoldersLoading(false)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getConnectorFolders, mapNewNode]
  )

  const onNodeExpand = (nodeId: string) => {
    const nodeData = searchNode(folderTree, nodeId)

    if (nodeData && !nodeData.loaded && !nodeData?.data?.length) {
      nodeData.loadingFolderData = true

      setTree(updateASingleFolder(folderTree, nodeData))

      getFoldersByParentId(nodeId, false)
    }
  }

  const onFolderListingOpen = useCallback(
    (connectorId: string, connectorName: string) => {
      if (!folderTree.length) {
        getFoldersByParentId(connectorId, true, connectorName)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [folderTree.length, getFoldersByParentId]
  )

  const cleanFolderTree = () => {
    setTree([])
  }

  return {
    isLoadingFolders,
    folderTree,
    errorOnFetchFolder,
    onFolderListingOpen,
    onNodeExpand,
    getConnectorFolders,
    cleanFolderTree,
    setErrorOnFetchFolder,
  }
}

export default useTreeViewNodes
