import React, { ReactElement } from "react"
import { LeftContainer, MainContainer, StyledHeader, Title } from "./styles"

type HeaderProps = {
  icon: ReactElement
  title: string
}

const CustomHeader = (props: HeaderProps) => {
  return (
    <MainContainer id="custom-header-container">
      <StyledHeader
        id="custom-header-content"
        position="relative"
        leadingArea={
          <LeftContainer id="left-container">
            <>
              {props.icon}
              <Title>{props.title}</Title>
            </>
          </LeftContainer>
        }
      />
    </MainContainer>
  )
}

export default CustomHeader
