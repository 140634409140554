import { Modal } from "@veneer/core"
import tokens from "@veneer/tokens"
import styled from "styled-components"

export const StyledModal = styled(Modal)`
  div:nth-child(2) {
    display: grid;
    div {
      justify-self: end;
    }
  }

  div.vn-modal--content {
    width: calc(400px + 100px + 100px);

    @media (max-width: ${tokens.smMin}) {
      width: auto;
      max-width: calc(100vw - 40px);
    }
  }

  button {
    min-width: 100px;
    min-height: 48px;
  }
  z-index: 1400 !important;
`

export const CustomTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`
export const CustomTitleText = styled.div`
  color: ${tokens.colorGray10};
  padding: 7px 0px 8px 7px;
`

export const CustomDescription = styled.p`
  color: ${tokens.colorGray10};
  padding-top: 24px;
`
