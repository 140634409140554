import { JarvisAuthProvider } from "@jarvis/web-http"
import React from "react"
import { DeviceProps } from "../../../mfe/types/device"
import PrinterWidgetDetails from "../PrinterWidgetDetails"
import PrinterWidgetShortcutInfo from "../PrinterWidgetShortcutInfo"
import { CardContentContainer, LineContainer, StyledCard } from "./styles"

type PrinterWidgetProps = {
  printer: DeviceProps
  saveCount: string
  emailCount: string
  printerStatus: string
  isLoadingStatus: boolean
  stack: number
  authProvider: JarvisAuthProvider
}

const PrinterWidget = (props: PrinterWidgetProps) => {
  const cloudId = props.printer.deviceId
  const deviceUuid = props.printer.identity.deviceUuid
  const CardContent = () => (
    <CardContentContainer id="card-content-container">
      <PrinterWidgetDetails
        printer={props.printer}
        printerStatus={props.printerStatus}
        isLoadingStatus={props.isLoadingStatus}
      />
      <LineContainer />
      <PrinterWidgetShortcutInfo
        stack={props.stack}
        authProvider={props.authProvider}
        deviceUuid={deviceUuid}
        cloudId={cloudId}
        emailCount={props.emailCount}
        saveCount={props.saveCount}
      />
    </CardContentContainer>
  )

  return <StyledCard id="printer-widget-container" content={CardContent()} />
}

export default PrinterWidget
