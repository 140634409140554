import { JarvisAssetsProvider } from "@jarvis/web-assets-provider"
import { fallbackLocalesConfig } from "./locale/helpers/fallbackLocales"
import localeToStringsJson from "./locale/index"

const DEFAULT_COUNTRY = "US"
const DEFAULT_LANGUAGE = "en"
let currentLocale
class I18n {
  country: string
  language: string
  assetsProvider: JarvisAssetsProvider

  private static instance: I18n

  constructor() {
    this.country = DEFAULT_COUNTRY
    this.language = DEFAULT_LANGUAGE
    this.assetsProvider = new JarvisAssetsProvider(
      localeToStringsJson,
      this.language,
      this.country
    )
  }

  setSupportedLocale(locale: string) {
    var index: number
    if (locale.replace("-", "_") in localeToStringsJson) {
      const [language, country] = locale.split("-")
      this.language = language
      this.country = country
      this.assetsProvider = new JarvisAssetsProvider(
        localeToStringsJson,
        language,
        country
      )
    } else if (
      (index = fallbackLocalesConfig.findIndex(
        (e) => e.locale === locale.replace("-", "_")
      )) > -1
    ) {
      const fallbackLocale = fallbackLocalesConfig[index].fallback
      const [language, country] = fallbackLocale.split("-")
      this.language = language
      this.country = country
      this.assetsProvider = new JarvisAssetsProvider(
        localeToStringsJson,
        language,
        country
      )
    } else {
      this.country = DEFAULT_COUNTRY
      this.language = DEFAULT_LANGUAGE
    }
    currentLocale = locale
  }

  getCurrentLocale() {
    const country = currentLocale.split("-")[1].toLowerCase()
    const language = currentLocale.split("-")[0].toLowerCase()

    return `/${country}/${language}`.toLowerCase()
  }

  public static get Instance() {
    return this.instance || (this.instance = new this())
  }
}

export const i18n = I18n.Instance
