import Tooltip from "@veneer/core/dist/scripts/tooltip"
import React from "react"
import { i18n } from "../../assets/I18n"
import { ProTag } from "./styles"

type ProTooltipProps = {
  type: string
}

const ProTooltip = ({ type }: ProTooltipProps) => {
  return (
    <Tooltip
      arrow
      content={i18n.assetsProvider.getText(
        "pages.ShortcutCreation.proTooltipDescription"
      )}
      placement="bottom"
      portal
    >
      <ProTag
        className="label"
        data-testid="pro-tag"
        pro={type}
        tag={i18n.assetsProvider.getText("pages.SolutionItem.tag")}
      />
    </Tooltip>
  )
}

export default ProTooltip
