import tokens from "@veneer/tokens"
import styled from "styled-components"

export const SearchContainer = styled.div<{ isFullBar?: boolean }>`
  width: ${(props) => (props.isFullBar ? "100%" : "40%")};

  @media screen and (max-width: ${tokens.smMin}) {
    width: 100%;
  }

  @media only screen and (min-width: 578px) and (max-width: ${tokens.mdMin}) {
    width: ${(props) => (props.isFullBar ? "100%" : "70%")};
    padding-right: 12px;
  }
`

export const SearchForm = styled.form`
  width: 100%;
`
