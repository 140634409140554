import tokens from "@veneer/tokens"
import styled from "styled-components"

export const MainContainer = styled.div`
  @keyframes fade-in {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  @keyframes fade-out {
    0% {
      opacity: 1;
    }

    100% {
      opacity: 0;
    }
  }

  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-color: white;
  z-index: 1399;
  animation: ${(props) =>
    props.fade === "fadeIn" ? "fade-in 125ms" : "fade-out 125ms"};
  display: ${(props) => (props.show ? "grid" : "none")};
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr auto;
  grid-template-areas:
    "header"
    "main"
    "footer";

  @media (max-width: ${tokens.mdMin}) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`

export const ContentContainer = styled.div`
  background-color: #fafafa;
  grid-area: "main";
  overflow: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  ::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
`
