import { JarvisAuthProvider } from "@jarvis/web-http"
import React, { useEffect } from "react"
import { useHistory, useParams } from "react-router-dom"

type AuthorizationRedirectProps = {
  stack: number
  authProvider: JarvisAuthProvider
}

const AuthorizationRedirect = (props: AuthorizationRedirectProps) => {
  const history = useHistory()
  const { base } = useParams()

  useEffect(() => {
    if (window.opener) {
      window.opener?.postMessage({ type: "signin" }, window.opener.origin)
      window.close()
    }

    history.push(`/printers`)
  }, [base, history])

  // FIXME: Hardcoded strings
  return (
    <div id="authorization-redirect-container">
      <h1>Redirecting authorization...</h1>
    </div>
  )
}

export default AuthorizationRedirect
