import { JarvisAuthProvider } from "@jarvis/web-http"
import { Button, ButtonGroup, TextBox, useToast } from "@veneer/core"
import React, { useState } from "react"
import { i18n } from "../../assets/I18n"
import { CapabilityItem } from "../../data/schemas/connector"
import useTenantApi from "../../hooks/api/useTenantApi"
import {
  InformationContainer,
  InformationText,
  StyledModal,
  TitleText,
} from "./styles"

type AccountNameModalProps = {
  stack: number
  authProvider: JarvisAuthProvider
  show: boolean
  providerSelected: CapabilityItem
  newAccountName: string
  repositories: CapabilityItem[]
  newAccountNameError: boolean
  success: { (editMode): void }
  onCancel: { (editMode): void }
  onClose: { (): void }
  onChangeName: { (newName: string): void }
  editMode: boolean
}

const AccountNameModal = (props: AccountNameModalProps) => {
  const [error, setError] = useState("")
  const [isLoading, setLoading] = useState(false)

  const { addToast } = useToast()
  const { editAccountName } = useTenantApi(props.stack, props.authProvider)

  const mountAccountName = async () => {
    try {
      setLoading(true)
      if (!props.newAccountName.trim()) {
        setError(
          i18n.assetsProvider.getText(
            "pages.ShortcutCreation.cloudSection.connectorNameCannotBeEmpty"
          )
        )
        return
      }

      if (props.newAccountNameError) {
        setError(
          i18n.assetsProvider.getText(
            "pages.ShortcutCreation.cloudSection.connectorNameSpecialCharactersNotAllowed"
          )
        )
        return
      }

      const names = props.repositories.map((repo) => repo.connectorName)
      if (names.includes(props.newAccountName.trim())) {
        setError(
          i18n.assetsProvider.getText("pages.ShortcutCreation.cloudSection.nicknameIsAlreadyInUse")
        )
        return
      }

      await editAccountName(props.providerSelected?.authorizationId!, {
        connectorId: props.providerSelected?.connectorId!,
        oldConnectorName: props.providerSelected?.connectorName!,
        newConnectorName: props.newAccountName,
      })

      props.success(props.editMode)
    } catch (error) {
      addToast({
        id: `smb-scan-destinations-mount-account-name-error`,
        type: "negative",
        text: i18n.assetsProvider.getText(
          "pages.ScanDestinations.toasts.somethingWentWrong"
        ),
      })
      props.onCancel(props.editMode)
    } finally {
      setLoading(false)
    }
  }

  function capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
  }

  return (
    <StyledModal
      id="edit-account-name-modal-container"
      onClose={props.onClose}
      show={props.show}
      footer={
        <ButtonGroup>
          <Button
            appearance="secondary"
            onClick={() => {
              props.onCancel(props.editMode)
              setError("")
            }}
            disabled={isLoading}
          >
            {i18n.assetsProvider.getText("common.cancel")}
          </Button>
          <Button
            aria-label="mount connector name save button"
            onClick={mountAccountName}
            loading={isLoading}
            disabled={error.length > 0}
          >
            {i18n.assetsProvider.getText("common.continue")}
          </Button>
        </ButtonGroup>
      }
    >
      <TitleText className="title-small">
        {props.editMode
          ? capitalizeFirstLetter(
              i18n.assetsProvider.getText(
                "pages.ShortcutCreation.cloudSection.tableActions.editAccountName"
              )
            )
          : i18n.assetsProvider.getText("pages.ShortcutCreation.cloudSection.createAccountName")}
      </TitleText>
      {error.length === 0 && (
        <InformationContainer>
          <InformationText>
            {i18n.assetsProvider.getText("pages.ShortcutCreation.cloudSection.accountNameInfoText")}
          </InformationText>
        </InformationContainer>
      )}
      <TextBox
        id="mount-account-name-text-box"
        aria-label="mount account name text box"
        label={i18n.assetsProvider.getText("pages.ShortcutCreation.cloudSection.tableLabels.accountName")}
        value={props.newAccountName}
        onChange={(value) => {
          props.onChangeName(value)
          setError("")
        }}
        helperText={error}
        error={error.length > 0}
        required={true}
      />
    </StyledModal>
  )
}

export default AccountNameModal
