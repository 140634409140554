import { IconChevronRight } from "@veneer/core"
import tokens from "@veneer/tokens"
import styled from "styled-components"

export const ProviderText = styled.div`
  color: ${tokens.colorGray10};
  overflow: hidden;
  overflow-wrap: anywhere;
  text-overflow: ellipsis;
  margin-bottom: 20px;
  font-size: 32px;
  line-height: 40px;
  @media (max-width: ${tokens.mdMin}) {
    font-size: 24px;
    line-height: 32px;
  }
`

export const ProviderDescription = styled.div`
  color: ${tokens.colorGray10};
  margin-bottom: 20px;
  font-size: 16px;
  line-height: 24px;
`

export const SidePanelContainer = styled.div`
  div[role="presentation"] {
    backdrop-filter: none !important;
    background: transparent !important;
  }
  #side-panel {
    overflow: auto;
    padding: 40px 32px 32px 32px;
    display: flex;
    flex-direction: column;
    button[aria-label="Close"] {
      gap: 8px;
      padding: 4px;
      width: 32px;
      height: 32px;
      position: absolute;
      color: ${tokens.colorGray8};
    }
    @media (max-width: 480px) {
      width: auto !important;
    }
  }
`

export const DisconnectAccButton = styled.button`
  color: #0076ad;
  background-color: transparent;
  border: none;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 20px;
  cursor: pointer;
  margin-left: -4px;
`

export const TopContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 18px 0px;
`

export const ConnectorItemName = styled.p`
  color: ${tokens.colorGray10};
  display: inline-block;
  line-height: 24px !important;
  height: fit-content;
`

export const ConnectorItemHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  margin-right: 10px;
  height: fit-content;
  overflow-wrap: anywhere;
`

export const ConnectorLabelBar = styled.div`
  height: 24px;
  width: fit-content;
  border-radius: 35px;
  font-size: 12px;
  display: flex;
  flex-direction: row;
  background-color: ${tokens.colorGray1};
  #provider-icon {
    margin: auto 4px auto 8px;
  }
  #provider-text {
    margin-right: 7.25px;
  }
`

export const NoAccountsContainer = styled.div`
  margin-top: 52px;
  display: flex;
  flex-direction: column;
  align-items: center;
`
export const WarningIconContainer = styled.div`
  border-radius: 100%;
  height: 36px;
  width: 36px;
  background-color: ${tokens.colorGray1};
  margin: 0px auto 12px auto;
  #warning-icon {
    margin: 6px;
  }
`

export const ConnectorItemContent = styled.div`
  padding: 16px 0px 16px 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 12px;
  width: 272px;
  height: auto;
  @media (max-width: 416px) {
    width: 55vw;
  }
`

export const ConnectorItemContainer = styled.div`
  margin: auto;
  border-style: solid;
  border-radius: 12px;
  margin-bottom: 16px;
  border-width: 1px;
  border-color: ${tokens.colorGray3};
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  #elipsis-icon {
    color: #014667;
    cursor: pointer;
    padding: 6px;
    border-radius: 8px;
    transition: background-color 0.1s ease-in-out;
  }
  #elipsis-icon:hover {
    background-color: #0278ab1a;
  }
`
export const AlignProTooltip = styled.div`
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 20px;
`

export const IconEllipsisContainer = styled.div`
  width: 36px;
  height: 36px;
  margin-right: 16px;
`

export const ItemDivider = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${tokens.colorGray10} 10%;
  &::after {
    content: "";
    border-bottom: 1px solid rgba(33, 33, 33, 0.1);
    width: 100%;
    background-color: rgba(33, 33, 33, 0.1);
  }
`

export const StyledIconChevronRight = styled(IconChevronRight)`
  justify-content: flex-end;
  margin: 0 16px;
`

export const ChevronContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;

  @media (max-width: ${tokens.smMin}) {
    width: auto;
  }
`

export const AuthAccFrame = styled.div`
  display: flex;
  border-radius: 14px;
  border: 1px solid rgba(33, 33, 33, 0.1);
  height: 300px;
  flex-direction: column;
  overflow-y: auto;
  margin: 18px 0px;
`

export const CustomErrorText = styled.p`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`

export const CustomErrorTitle = styled.p`
  font-size: 16px;
  line-height: 20px;
  text-align: center;
`

export const CustomErrorDescription = styled.p`
  font-size: 14px;
  line-height: 20px;
  text-align: center;
`

export const ErrorWidgetContainer = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
  button {
    justify-content: center !important;
  }
`
