import { Modal } from "@veneer/core"
import tokens from "@veneer/tokens"
import styled from "styled-components"

export const StyledModal = styled(Modal)<{ wide: boolean }>`
  z-index: 1400 !important;
  div.vn-modal--content {
    max-width: 570px;
    padding: 40px;
    width: auto;
  }

  div.vn-modal--body {
    overflow-y: hidden;
    padding: 2px;
  }

  div.vn-modal--footer {
    display: grid;
    div {
      justify-self: end;
    }
  }
`

export const TitleText = styled.div`
  color: ${tokens.colorGray10};
  padding-bottom: 16px;
  font-size: 32px;
  line-height: 40px;
`

export const InformationContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 4px;
  padding-bottom: 12px;
`

export const InformationText = styled.p`
  color: ${tokens.colorGray10};
  font-size: 16px;
  line-height: 24px;
`
