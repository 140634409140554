import { FolderItem, FolderStructure } from "../data/schemas/folders"

export const mapTreeData = (
  currentTree: FolderItem[],
  folder: FolderStructure
): FolderItem[] => {
  const treeData = currentTree.map((node) => {
    if (node.id === folder.parent.id) {
      return {
        ...node,
        id: node.id,
        type: node.type,
        name: node.name,
        loaded: true,
        data: folder.data,
        loadingFolderData: false,
      }
    }

    return {
      ...node,
      id: node.id,
      type: node.type,
      name: node.name,
      data: node.data && mapTreeData(node.data, folder),
    }
  })
  return treeData
}

export const updateASingleFolder = (
  folderTree: FolderItem[],
  folder: FolderItem
) => {
  const index = folderTree.findIndex(
    (folderItem) => folderItem.id === folder.id
  )
  folderTree[index] = folder
  return folderTree
}

export const flattenTree = (tree: FolderItem[]): FolderItem[] =>
  tree.reduce((flattened: FolderItem[], current) => {
    if (!tree || !tree.length) {
      return flattened.concat(current)
    }

    return flattened.concat(
      current,
      Array.isArray(current.data) ? flattenTree(current.data) : []
    )
  }, [])

export const searchNode = (
  tree: FolderItem[],
  nodeId: string
): FolderItem | undefined =>
  flattenTree(tree).find((node: FolderItem) => node.id === nodeId)
