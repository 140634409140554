import authBox from "./authBox.svg"
import authDropbox from "./authDropbox.svg"
import authEvernote from "./authEvernote.svg"
import authGoogleDrive from "./authGoogleDrive.svg"
import authOneDrive from "./authOneDrive.svg"
import authOneDriveBusiness from "./authOneDriveBusiness.svg"
import authQuickbooks from "./authQuickbooks.svg"
import authSharepoint from "./authSharepoint.svg"
import box from "./box.svg"
import checkMarkCircle from "./checkMarkCircle.svg"
import cloud from "./cloud.svg"
import controlPanel from "./controlPanel.svg"
import dropBox from "./dropbox.svg"
import email from "./email.svg"
import emailAction from "./emailAction.svg"
import evernote from "./evernote.svg"
import googleDrive from "./googleDrive.svg"
import oneDrive from "./one-drive.svg"
import printerMock from "./printerMock.svg"
import quickBooks from "./quickbooks.svg"
import scanToCloud from "./scanToCloud.svg"
import scanToCloudDisabled from "./scanToCloudDisabled.svg"
import scanToEmail from "./scanToEmail.svg"
import scanToEmailDisabled from "./scanToEmailDisabled.svg"
import sharepoint from "./sharepoint.svg"
import uploadAction from "./uploadAction.svg"

const Images = {
  printerMock,
  email,
  emailAction,
  uploadAction,
  box,
  dropBox,
  oneDrive,
  evernote,
  sharepoint,
  quickBooks,
  googleDrive,
  cloud,
  authBox,
  authDropbox,
  authEvernote,
  authGoogleDrive,
  authOneDrive,
  authOneDriveBusiness,
  authQuickbooks,
  authSharepoint,
  scanToCloud,
  scanToEmail,
  scanToEmailDisabled,
  scanToCloudDisabled,
  checkMarkCircle,
  controlPanel,
}

export default Images
