import { JarvisAuthProvider } from "@jarvis/web-http"
import { Button, ButtonGroup, IconWarningAlt, useToast } from "@veneer/core"
import React from "react"
import { useHistory, useParams } from "react-router-dom"
import { i18n } from "../../assets/I18n"
import useDeviceShadowApi from "../../hooks/api/useDeviceShadow"
import { StyledModal } from "./styles"

interface DisableScanDestinationsModalProps {
  stack: number
  authProvider: JarvisAuthProvider
  cloudId: string
  isOpen: boolean
  onClose: () => void
}

const DisableScanDestinationsModal: React.FC<
  DisableScanDestinationsModalProps
> = ({ stack, authProvider, cloudId, isOpen, onClose }) => {
  const history = useHistory()
  const { base } = useParams()
  const { addToast } = useToast()

  const { enableScanDestinationConfiguration } = useDeviceShadowApi(
    stack,
    authProvider
  )

  const handleConfirmDisableScanDestinations = async () => {
    try {
      await enableScanDestinationConfiguration(cloudId, 15000, "false")
      history.push(`${base}/${cloudId}`)
    } catch (error) {
      onClose()
      addToast({
        id: `smb-scan-destinations-mount-account-name-error`,
        type: "negative",
        text: i18n.assetsProvider.getText(
          "pages.ScanDestinations.toasts.somethingWentWrong"
        ),
      })
    }
  }
  return (
    <div style={{ position: "relative" }}>
      <StyledModal
        title={
          <>
            <div style={{ display: "flex", alignItems: "center" }}>
              <IconWarningAlt
                size={35}
                color="colorOrange6"
                filled
                customStyle={{ marginBottom: "5px", marginRight: "5px" }}
              />
              <p>{i18n.assetsProvider.getText("pages.ScanDestinations.printerDetails.modal.title")}</p>
            </div>
          </>
        }
        type="warning"
        align="start"
        id="disable-scan-destinations-modal"
        show={isOpen}
        onClose={onClose}
        footer={
          <ButtonGroup>
            <Button
              id="disable-scan-destinations-cancel-button"
              appearance="secondary"
              onClick={onClose}
            >
              {i18n.assetsProvider.getText("common.cancel")}
            </Button>
            <Button
              id="disable-scan-destinations-cancel-button"
              appearance="primary"
              onClick={handleConfirmDisableScanDestinations}
            >
              {i18n.assetsProvider.getText("common.disable")}
            </Button>
          </ButtonGroup>
        }
      >
        <p>
        {i18n.assetsProvider.getText("pages.ScanDestinations.printerDetails.modal.description")}
        </p>
      </StyledModal>
    </div>
  )
}

export default DisableScanDestinationsModal
