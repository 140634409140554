import { CapabilityItem } from "../data/schemas/connector"

export const resolveCapabilitiesId = (capabilities: CapabilityItem[]) => {
  const listOfCapabilitiesIds: string[] = []

  for (const capability of capabilities) {
    if (capability.connectorId) {
      listOfCapabilitiesIds.push(capability.connectorId)
    }
  }

  return listOfCapabilitiesIds
}
