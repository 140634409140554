import { Card, Tabs } from "@veneer/core"
import styled from "styled-components"

export const ScanDestinationsContainer = styled.div`
  max-width: 100%;
`

export const StyledCard = styled(Card)`
  padding: 10px 20px 20px;
`
export const ScanDestinationsTabsContainer = styled.div`
  margin-top: 30px;
  padding: 0 30px;
`

export const CustomTabs = styled(Tabs)<{
  scanToEmailCount: string
  scanToCloudCount: string
}>`
  div:first-child {
    button {
      box-shadow: none !important;
      background-color: #fafafa !important;
      color: #666666;
    }
  }
  div:last-child {
    div {
      height: auto;
    }
    div:first-child span::after,
    div:last-child span::after {
      display: inline-block;
      width: 24px;
      height: 16px;
      padding: 4px 5px;
      margin-left: 8px;
      border-radius: 24px;
      font-size: 12px;
      line-height: 16px;
    }
    & div:first-child span::after {
      background: ${(props) =>
        props.scanToEmailCount === "0" ? "#E8E8E8" : "#027aae"};
      content: "${(props) => props.scanToEmailCount}";
      color: ${(props) =>
        props.scanToEmailCount === "0" ? "#A3A3A3" : "#fff"};
    }
    & div:last-child span::after {
      background: ${(props) =>
        props.scanToCloudCount === "0" ? "#E8E8E8" : "#027aae"};
      content: "${(props) => props.scanToCloudCount}";
      color: ${(props) =>
        props.scanToCloudCount === "0" ? "#A3A3A3" : "#fff"};
    }
  }
`

export const ErrorWidgetContainer = styled.div`
  padding: 20% 0;
`
