import styled from "styled-components"

export const CheckMark = styled.div`
  && {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 4px;
  }
`
