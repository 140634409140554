import { providers } from "../data/enum/providers"
import { CapabilityItem } from "../data/schemas/connector"

export const resolveConnectorName = (
  connectorsList: CapabilityItem[],
  connectorType: string
) => {
  const connectorName = providers[connectorType].name
  let count = 1
  let newNameFound = false
  let connectorRealName = `${connectorName} - ${count}`

  while (!newNameFound) {
    // eslint-disable-next-line no-loop-func
    const matches = connectorsList.some((connector: CapabilityItem) => {
      return connector.connectorName === connectorRealName
    })
    if (matches) {
      connectorRealName = `${connectorName} - ${count++}`
    } else {
      newNameFound = true
    }
  }

  return connectorRealName
}
