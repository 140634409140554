import { ProgressIndicator } from "@veneer/core"
import React from "react"
import { i18n } from "../../assets/I18n"
import { Container, Text } from "./styles"

const LoaderScanDestinations = ({ isLoading, scanDestinationsEnabled }) => {
  return (
    <>
      {isLoading && (
        <Container className="loader-overlay">
          <ProgressIndicator />
          <Text data-testid="text-loader">
            {!scanDestinationsEnabled &&
              i18n.assetsProvider.getText(
                "pages.ScanDestinations.loader.enabling"
              )}
          </Text>
        </Container>
      )}
    </>
  )
}

export default LoaderScanDestinations
