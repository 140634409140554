import { asyncWithLDProvider } from "launchdarkly-react-client-sdk"
import React, { ReactNode, useEffect, useState } from "react"
import { getLDClientSideId } from "../helpers/ldHelper"

type CustomLDProviderProps = {
  stack: number
  children: ReactNode
}
const CustomLDProvider = ({ stack, children }: CustomLDProviderProps) => {
  const [LDProvider, setLDProvider] = useState<React.FC | null>(null)

  useEffect(() => {
    const fetchLDProvider = async () => {
      try {
        const fetchedLDProvider = await asyncWithLDProvider({
          clientSideID: getLDClientSideId(stack),
          options: {
            bootstrap: "localStorage",
            streaming: false,
          },
          reactOptions: {
            useCamelCaseFlagKeys: true,
          },
        })
        setLDProvider(() => fetchedLDProvider)
      } catch (error) {
        console.error("error", error)
      }
    }
    fetchLDProvider()
  }, [stack])

  if (LDProvider) {
    return <LDProvider>{children}</LDProvider>
  }

  return <>{children}</>
}

export default CustomLDProvider
