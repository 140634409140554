import styled from "styled-components"

export const LoaderContainer = styled.div<{
  isQuickSetModalEnabled: boolean
  showMessage: boolean
}>`
  padding: ${(props) => (props.isQuickSetModalEnabled ? "0 17px 0 0" : "0px")};
  display: flex;
  align-items: center;
  justify-content: ${(props) => (props.showMessage ? "center" : "flex-end")};
  ${(props) => props.showMessage && "flex-direction: column;"}

  .message {
    margin-top: 10px;
    color: #737373;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
  }
`
