import { JarvisAuthProvider } from "@jarvis/web-http"
import { DeviceApiClient } from "@jarvis/web-stratus-client"
import { useCallback, useState } from "react"
import { DeviceStatusResponse } from "../../data/schemas/deviceApi"

const useDeviceApi = (stack: number, authProvider: JarvisAuthProvider) => {
  const [isLoadingStatus, setLoading] = useState(false)
  const [statusData, setData] = useState<DeviceStatusResponse>()
  const [error, setError] = useState({})

  const getDeviceStatus = useCallback(
    async (cloudId: string, timeout = 15000) => {
      try {
        setLoading(true)
        const client = new DeviceApiClient(stack, authProvider, timeout)
        const { data } = await client.getDeviceStatus(cloudId)
        setData(data)
      } catch (error) {
        setError(error)
      } finally {
        setLoading(false)
      }
    },
    [authProvider, stack]
  )

  return {
    isLoadingStatus,
    statusData,
    error,
    getDeviceStatus,
  }
}

export default useDeviceApi
