import { i18n } from "../../assets/I18n"

export const templateCreateContactYourself = (
  nameAdmin: string,
  emailAdmin: string,
  organizationName: string
) => {
  return {
    version: "1",
    category: ["email"],
    smartTask: {
      fileType: "pdf",
      jobName: `${nameAdmin}`,
      smartTaskConfig: {
        email: {
          tos: [emailAdmin],
           subject: i18n.assetsProvider.getText(
             "pages.ShortcutCreation.emailSection.defaultSubjectDescription",
             {
               businessName: `[${
                 organizationName ??
                 i18n.assetsProvider.getText(
                   "pages.ShortcutCreation.emailSection.businessName"
                 )
               }]`,
             }
           ),
           message: i18n.assetsProvider.getText(
             "pages.ShortcutCreation.emailSection.defaultBodyDescription",
             {
               businessName: `[${
                 organizationName ??
                 i18n.assetsProvider.getText(
                   "pages.ShortcutCreation.emailSection.businessName"
                 )
               }]`,
             }
           ),
        },
      },
    },
  }
}
