import emojiRegex from "emoji-regex"
import { useEffect, useState } from "react"
import { i18n } from "../../assets/I18n"

const useTitleValidation = () => {
  const [title, setTitle] = useState("")
  const [titleError, setTitleError] = useState("")

  const emoji = emojiRegex()

  const isValidTitle =
    !title.match(emoji) &&
    /^[^%#/:*?\\"<>()|{}~`!@$^&+=´]+$/.test(title) &&
    !/(^[.])|([.]$)|([[\]])/.test(title)

  useEffect(() => {
    setTitleError(
      title && !isValidTitle
        ? i18n.assetsProvider.getText(
            "pages.ShortcutCreation.validators.invalidNameMessage"
          )
        : ""
    )
  }, [title, isValidTitle])

  return {
    title,
    setTitle,
    titleError,
  }
}

export default useTitleValidation
