import { Button, Modal } from "@veneer/core"
import tokens from "@veneer/tokens"
import styled from "styled-components"

export const StyledModal = styled(Modal)`
  z-index: 1400 !important;
  display: flex !important;
  flex-direction: column !important;
  height: 100%;
  overflow: hidden;
  width: auto;

  div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  /* Works on Firefox */
  div {
    scrollbar-width: thin;
    scrollbar-color: rgba(115, 115, 115, 0.4) rgba(250, 250, 250, 1);
  }
  /* Works on Chrome, Edge, and Safari */
  div::-webkit-scrollbar {
    width: 18px !important;
  }
  div::-webkit-scrollbar-track {
    background: #fafafa !important;
  }
  div::-webkit-scrollbar-thumb {
    background-color: rgba(115, 115, 115, 0.5) !important;
    border-radius: 10px;
    background-clip: padding-box;
    border: 5px solid #fafafa !important;
  }

  span {
    padding-top: 2px;
  }

  #modal-select-folder-test-label {
    align-self: flex-start !important;
    color: ${tokens.colorGray10} !important;

    @media (max-width: ${tokens.mdMax}) {
      width: 100% !important;
    }
  }

  div.vn-modal--content {
    max-height: 80%;
    @media (max-width: ${tokens.mdMax}) {
      width: calc(400px + 48px + 48px);
    }

    @media (max-width: ${tokens.xsMax}) {
      width: 90%;
    }

    @media (max-width: 440px) {
      width: 85%;
    }
  }

  div.vn-modal--dialog {
    border-radius: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100% !important;

    div.vn-modal--footer {
      width: 100%;

      @media (max-width: 350px) {
        width: auto;
      }
    }

    div.vn-modal--footer > div {
      flex-direction: row-reverse;

      @media (max-width: 300px) {
        flex-direction: column-reverse;
        gap: 5px;
      }
    }

    div.vn-modal--footer > div button:not(:last-child) {
      margin-left: 16px;

      @media (max-width: 300px) {
        margin-left: 0px;
      }
    }

    div.vn-modal--body {
      max-height: 420px !important;
      max-width: 500px;
      align-content: center;
      width: 100%;
      overflow: auto;
      display: block;
      border-style: solid;
      border-width: 2px;
      border-radius: 16px;
      border-color: rgba(33, 33, 26, 0.05);
      padding: 10px 0px;
      & > ul > li > div {
        flex-direction: row !important;
        background-color: transparent !important;
      }

      div.vn-animated-height__wrapper-inner > ul > li > div {
        flex-direction: row !important;
      }

      #loading-data {
        margin-left: -33px;
        span {
          color: ${tokens.colorGray5};
          font-size: 16px;
          line-height: 20px;
        }
      }
    }

    div.vn-modal--body:has(> #error-widget-container) {
      border-radius: 0px;
      padding: 0px;
    }

    button {
      display: flex;
      justify-content: center;
    }
  }
`

export const FooterContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-self: flex-end;
`

export const CustomButton = styled(Button)`
  width: 30% !important;
`

export const LoaderContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 80px 0;
`
export const LoaderText = styled.div`
  margin-top: 16px;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #737373;
`
