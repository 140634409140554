import { Button, ButtonGroup, Checkbox, InlineNotification } from "@veneer/core"
import React from "react"
import { i18n } from "../../assets/I18n"
import { StyledModal } from "./styles"

interface EnableScanDestinationsModalProps {
  isOpen: boolean
  isCheckboxEnabled: boolean
  onClose: () => void
  onConfirm: () => void
  onChange: () => void
}

const EnableScanDestinationsModal: React.FC<
  EnableScanDestinationsModalProps
> = ({ isOpen, isCheckboxEnabled, onClose, onConfirm, onChange }) => {
  return (
    <div style={{ position: "relative" }}>
      <StyledModal
        title={i18n.assetsProvider.getText(
          "pages.SolutionItem.enableScanDestinationsModal.title"
        )}
        align="start"
        closeButton
        id="enable-scan-destinations-modal"
        show={isOpen}
        onClose={onClose}
        footer={
          <ButtonGroup>
            <Button
              id="enable-scan-destinations-cancel-button"
              appearance="secondary"
              onClick={onClose}
            >
              {i18n.assetsProvider.getText("common.cancel")}
            </Button>
            <Button
              id="enable-scan-destinations-confirm-button"
              onClick={onConfirm}
            >
              {i18n.assetsProvider.getText("pages.SolutionItem.enableScanDestinationsModal.button.confirm")}
            </Button>
          </ButtonGroup>
        }
      >
        <p>
          {i18n.assetsProvider.getText(
            "pages.SolutionItem.enableScanDestinationsModal.description"
          )}
        </p>
        <p>
          {i18n.assetsProvider.getText(
            "pages.SolutionItem.enableScanDestinationsModal.note"
          )}
        </p>
        <InlineNotification
          closeButton={false}
          title={i18n.assetsProvider.getText("pages.SolutionItem.enableScanDestinationsModal.inlineNotification.title")}
          description={i18n.assetsProvider.getText("pages.SolutionItem.enableScanDestinationsModal.inlineNotification.description")}
          type="informative"
        />
        <Checkbox
          checked={isCheckboxEnabled}
          onChange={onChange}
          id="checkbox"
          label={i18n.assetsProvider.getText("pages.SolutionItem.enableScanDestinationsModal.checkbox.label")}
        />
      </StyledModal>
    </div>
  )
}

export default EnableScanDestinationsModal
