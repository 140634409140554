export const fallbackLocalesConfig = [
  { locale: "en_AD", fallback: "en-US" },
  { locale: "en_AE", fallback: "en-US" },
  { locale: "en_AF", fallback: "en-US" },
  { locale: "en_AG", fallback: "en-US" },
  { locale: "en_AI", fallback: "en-US" },
  { locale: "en_AL", fallback: "en-US" },
  { locale: "en_AM", fallback: "en-US" },
  { locale: "en_AU", fallback: "en-US" },
  { locale: "en_AW", fallback: "en-US" },
  { locale: "en_AZ", fallback: "en-US" },
  { locale: "en_BA", fallback: "en-US" },
  { locale: "en_BB", fallback: "en-US" },
  { locale: "en_BD", fallback: "en-US" },
  { locale: "en_BH", fallback: "en-US" },
  { locale: "en_BN", fallback: "en-US" },
  { locale: "en_BS", fallback: "en-US" },
  { locale: "en_BT", fallback: "en-US" },
  { locale: "en_BW", fallback: "en-US" },
  { locale: "en_BZ", fallback: "en-US" },
  { locale: "en_CM", fallback: "en-US" },
  { locale: "en_CR", fallback: "en-US" },
  { locale: "en_DZ", fallback: "en-US" },
  { locale: "en_EG", fallback: "en-US" },
  { locale: "en_ET", fallback: "en-US" },
  { locale: "en_FJ", fallback: "en-US" },
  { locale: "en_GB", fallback: "en-US" },
  { locale: "en_GD", fallback: "en-US" },
  { locale: "en_GE", fallback: "en-US" },
  { locale: "en_GH", fallback: "en-US" },
  { locale: "en_HK", fallback: "en-US" },
  { locale: "en_ID", fallback: "en-US" },
  { locale: "en_IE", fallback: "en-US" },
  { locale: "en_IL", fallback: "en-US" },
  { locale: "en_IN", fallback: "en-US" },
  { locale: "en_IS", fallback: "en-US" },
  { locale: "en_JM", fallback: "en-US" },
  { locale: "en_JO", fallback: "en-US" },
  { locale: "en_JP", fallback: "en-US" },
  { locale: "en_KE", fallback: "en-US" },
  { locale: "en_KH", fallback: "en-US" },
  { locale: "en_KN", fallback: "en-US" },
  { locale: "en_KW", fallback: "en-US" },
  { locale: "en_KY", fallback: "en-US" },
  { locale: "en_LA", fallback: "en-US" },
  { locale: "en_LB", fallback: "en-US" },
  { locale: "en_LC", fallback: "en-US" },
  { locale: "en_LK", fallback: "en-US" },
  { locale: "en_LY", fallback: "en-US" },
  { locale: "en_MA", fallback: "en-US" },
  { locale: "en_ME", fallback: "en-US" },
  { locale: "en_MK", fallback: "en-US" },
  { locale: "en_MM", fallback: "en-US" },
  { locale: "en_MN", fallback: "en-US" },
  { locale: "en_MS", fallback: "en-US" },
  { locale: "en_NZ", fallback: "en-US" },
  { locale: "en_MT", fallback: "en-US" },
  { locale: "en_MY", fallback: "en-US" },
  { locale: "en_MU", fallback: "en-US" },
  { locale: "en_MV", fallback: "en-US" },
  { locale: "en_NA", fallback: "en-US" },
  { locale: "en_NG", fallback: "en-US" },
  { locale: "en_NP", fallback: "en-US" },
  { locale: "en_OM", fallback: "en-US" },
  { locale: "en_PG", fallback: "en-US" },
  { locale: "en_PH", fallback: "en-US" },
  { locale: "en_PK", fallback: "en-US" },
  { locale: "en_PR", fallback: "en-US" },
  { locale: "en_QA", fallback: "en-US" },
  { locale: "en_RS", fallback: "en-US" },
  { locale: "en_RS", fallback: "en-US" },
  { locale: "en_RW", fallback: "en-US" },
  { locale: "en_SA", fallback: "en-US" },
  { locale: "en_SG", fallback: "en-US" },
  { locale: "en_SX", fallback: "en-US" },
  { locale: "en_TC", fallback: "en-US" },
  { locale: "en_TH", fallback: "en-US" },
  { locale: "en_TJ", fallback: "en-US" },
  { locale: "en_TL", fallback: "en-US" },
  { locale: "en_TM", fallback: "en-US" },
  { locale: "en_TN", fallback: "en-US" },
  { locale: "en_TT", fallback: "en-US" },
  { locale: "en_TZ", fallback: "en-US" },
  { locale: "en_UG", fallback: "en-US" },
  { locale: "en_VC", fallback: "en-US" },
  { locale: "en_VG", fallback: "en-US" },
  { locale: "en_VN", fallback: "en-US" },
  { locale: "en_WS", fallback: "en-US" },
  { locale: "en_ZA", fallback: "en-US" },
  { locale: "en_ZM", fallback: "en-US" },

  { locale: "es_AR", fallback: "es-ES" },
  { locale: "es_BO", fallback: "es-ES" },
  { locale: "es_CL", fallback: "es-ES" },
  { locale: "es_CO", fallback: "es-ES" },
  { locale: "es_CU", fallback: "es-ES" },
  { locale: "es_DO", fallback: "es-ES" },
  { locale: "es_EC", fallback: "es-ES" },
  { locale: "es_GT", fallback: "es-ES" },
  { locale: "es_HN", fallback: "es-ES" },
  { locale: "es_MX", fallback: "es-ES" },
  { locale: "es_NI", fallback: "es-ES" },
  { locale: "es_PA", fallback: "es-ES" },
  { locale: "es_PE", fallback: "es-ES" },
  { locale: "es_PY", fallback: "es-ES" },
  { locale: "es_SV", fallback: "es-ES" },
  { locale: "es_UY", fallback: "es-ES" },
  { locale: "es_VE", fallback: "es-ES" },

  { locale: "fr_BE", fallback: "fr-FR" },
  { locale: "fr_BJ", fallback: "fr-FR" },
  { locale: "fr_BL", fallback: "fr-FR" },
  { locale: "fr_CH", fallback: "fr-FR" },
  { locale: "fr_CI", fallback: "fr-FR" },
  { locale: "fr_GF", fallback: "fr-FR" },
  { locale: "fr_GP", fallback: "fr-FR" },
  { locale: "fr_HT", fallback: "fr-FR" },
  { locale: "fr_LU", fallback: "fr-FR" },
  { locale: "fr_MC", fallback: "fr-FR" },
  { locale: "fr_MF", fallback: "fr-FR" },
  { locale: "fr_MG", fallback: "fr-FR" },
  { locale: "fr_MQ", fallback: "fr-FR" },
  { locale: "fr_NC", fallback: "fr-FR" },
  { locale: "fr_PF", fallback: "fr-FR" },
  { locale: "fr_RE", fallback: "fr-FR" },
  { locale: "fr_SN", fallback: "fr-FR" },
  { locale: "fr_YT", fallback: "fr-FR" },

  { locale: "de_AT", fallback: "de-DE" },
  { locale: "de_LI", fallback: "de-DE" },

  { locale: "it_SM", fallback: "it-IT" },
  { locale: "it_VA", fallback: "it-IT" },

  { locale: "nl_BQ", fallback: "nl-NL" },
  { locale: "nl_CW", fallback: "nl-NL" },

  { locale: "pt_AO", fallback: "pt-PT" },
  { locale: "pt_MZ", fallback: "pt-PT" },

  { locale: "ro_MD", fallback: "ro-RO" },

  { locale: "ru_BY", fallback: "ru-RU" },
  { locale: "ru_KZ", fallback: "ru-RU" },
  { locale: "ru_UA", fallback: "ru-RU" },
  { locale: "ru_UZ", fallback: "ru-RU" },

  { locale: "zh_HK", fallback: "zh-CN" },
  { locale: "zh_MO", fallback: "zh-CN" },
  { locale: "zh_SG", fallback: "zh-CN" },
]
