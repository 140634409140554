import { Footer } from "@veneer/core"
import tokens from "@veneer/tokens"
import styled from "styled-components"

export const MainContainer = styled.div`
  text-align: center;
  grid-area: "footer";
  > button {
    margin-left: 12px;
  }

  @media (max-width: ${tokens.smMin}) {
    width: 100%;
    height: fit-content !important;
    display: flex;
    flex-direction: column;
    gap: 10px;

    button {
      margin-left: 0;
      width: 100%;
      div {
        width: 24px;
        height: 24px;
      }

      :nth-child(1) {
        order: 2 !important;
      }

      :nth-child(2) {
        order: 1 !important;
      }
    }
  }
`

export const StyledFooter = styled(Footer)`
  position: relative;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100% !important;
  box-shadow: 0px -2px 6px 0px rgba(33, 33, 33, 0.1);
  > div {
    border-color: ${tokens.colorGray0};
  }

  @media (max-width: ${tokens.smMin}) {
    div {
      width: 100%;
      height: auto;
    }
  }
`
