import { CustomImage, IconWarningAlt } from "@veneer/core"
import tokens from "@veneer/tokens"
import styled from "styled-components"

export const FormContainer = styled.div`
  display: grid;
  gap: 24px;
  padding: 32px;
  margin: 0 auto;
  grid-template-columns: 1fr 2fr;
  align-items: flex-start;
  @media (max-width: ${tokens.mdMin}) {
    grid-template-columns: 1fr;
  }
`

export const StyledCustomImage = styled(CustomImage)`
  @media (max-width: ${tokens.lgMin}) {
    padding-top: 8px;
  }
  @media (max-width: ${tokens.smMin}) {
    padding-top: 13px;
  }
`

export const StyledTitleIcon = styled(IconWarningAlt)`
  color: ${tokens.colorOrange6} !important;
  margin-top: 2px;
`
