import { CustomImage, Modal } from "@veneer/core"
import tokens from "@veneer/tokens"
import styled from "styled-components"

export const StyledModal = styled(Modal)`
  div:nth-child(${(closeButton: boolean) => (closeButton ? 3 : 2)}) {
    display: grid;

    div {
      justify-self: end;
    }
  }

  div.vn-modal--footer {
    display: flex;
    flex-direction: row-reverse;
    div:first-child {
      gap: 16px;
      button:first-child {
        margin-right: 0px;
      }
    }
    @media (max-width: ${tokens.smMin}) {
      div:first-child {
        gap: 16px;
        flex-direction: column-reverse;
      }
    }
  }

  div.vn-modal--dialog {
    display: flex;
    justify-content: center;
    align-items: center;

    ${({ isInfoModal }) =>
      isInfoModal &&
      `
      @media (max-width: ${tokens.smMin}) {
        align-items: flex-start;
      }
        `}
  }

  div.vn-modal--content {
    width: calc(400px + 100px + 100px);

    @media (max-width: ${tokens.smMin}) {
      width: auto;
      max-width: calc(100vw - 40px);
    }

    ${({ isInfoModal }) =>
      isInfoModal &&
      `
        width: 496px;
        height: max-content;
        word-wrap: break-word;
        overflow-wrap: break-word;
        padding: 40px 48px;
        box-sizing: unset !important;

        @media (max-width: ${tokens.smMin}) {
          width: 272px;
        }
      `}
  }

  button.vn-modal__close {
    min-width: 50px;
  }

  button {
    min-width: 112px;
    min-height: 48px;
  }
  z-index: 1400 !important;
`

export const CustomTitle = styled.div`
  gap: 8px;
  margin-top: -5px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`
export const CustomTitleText = styled.div`
  color: ${tokens.colorGray10};
  padding-top: 7px;
`

export const DescriptionContainer = styled.div`
  padding-top: 24px;
`
export const CustomDescription = styled.p`
  color: ${tokens.colorGray10};
  font-size: 16px !important;
  ${({ isInfoModal }) =>
    isInfoModal &&
    `display: flex !important;
     letter-spacing: 0.02em;
     font-weight: 400 !important;
     padding-top: 1px !important;
     line-height: 24px !important;
     overflow-wrap: break-word;`}
  display: inline;
`

export const CustomCurrentUser = styled.span`
  font-weight: 700;
  display: inline;
`

export const CustomUpDescription = styled.p`
  ${({ isInfoModal }) =>
    isInfoModal &&
    `font-size: 16px !important;
     padding-bottom: 20px !important;
     padding-top: 19px !important;
     letter-spacing: 0.02em !important;
    `}
  padding-top: 24px;
`

export const NoticeContainer = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  background-color: rgb(235, 232, 255);
  border-radius: 12px;

  @media (max-width: 300px) {
    display: flex !important;
    flex-direction: column !important;
    align-items: flex-start !important;
  }
`

export const NoticeTitle = styled.div`
  color: ${tokens.colorGray10};
  font-size: 16px;
  padding-bottom: 0px;
`

export const NoticeDescription = styled.div`
  padding-top: 0px;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.04em;
`

export const NoticeIcon = styled.div`
  margin-top: 14px;
  margin-left: 14px;
`
export const NoticeText = styled.div`
  margin-top: 14px;
  display: flex;
  flex-direction: column;
  padding-left: 14px;
  padding-right: 12px;
  margin-bottom: 14px;
`

export const ImageContainer = styled.div`
  display: flex !important;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;

  @media (max-width: ${tokens.smMin}) {
    display: flex !important;
  }
`

export const StyledCustomImage = styled(CustomImage)`
  width: 150% !important;
  height: auto !important;
  padding: 14px 28px;
  justify-content: center;
  align-items: center;
`
