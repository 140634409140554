export type ocrLanguageConfigOptions = {
  value:
    | "ar"
    | "pt-BR"
    | "bg"
    | "ca"
    | "zh"
    | "zh-cn"
    | "zh-Hans"
    | "zh-tw"
    | "zh-Hant"
    | "hr"
    | "cs"
    | "da"
    | "nl"
    | "en-BR"
    | "en"
    | "en-US"
    | "fi"
    | "fr"
    | "de"
    | "el"
    | "he"
    | "hu"
    | "id"
    | "it"
    | "ja"
    | "ko"
    | "nb"
    | "no"
    | "nn"
    | "pl"
    | "pt"
    | "ro"
    | "ru"
    | "sk"
    | "sl"
    | "es"
    | "sv"
    | "tr"
    | "et"
    | "Gaelic"
    | "lv"
    | "lt"
    | "de-LU"
    | "mt"
    | "mi"
    | "is"
  label: string
}

export const ocrLanguageConfigOptionsList: ocrLanguageConfigOptions[] = [
  { value: "ar", label: "الشرق الأوسط" },
  { value: "bg", label: "Български" },
  { value: "ca", label: "Català" },
  { value: "cs", label: "Čeština" },
  { value: "da", label: "Dansk" },
  { value: "de", label: "Deutsch" },
  { value: "el", label: "Ελληνικά" },
  { value: "en", label: "English" },
  { value: "en-BR", label: "English (UK)" },
  { value: "en-US", label: "English (US)" },
  { value: "es", label: "Español" },
  { value: "fi", label: "Suomi" },
  { value: "fr", label: "Français" },
  { value: "he", label: "עברית" },
  { value: "hr", label: "Hrvatski" },
  { value: "hu", label: "Magyar" },
  { value: "id", label: "Indonesia" },
  { value: "it", label: "Italiano" },
  { value: "ja", label: "日本語" },
  { value: "ko", label: "한국어" },
  { value: "nb", label: "Norsk" },
  { value: "nl", label: "Nederlands" },
  { value: "pl", label: "Polski" },
  { value: "pt", label: "Português" },
  { value: "pt-BR", label: "Português Brasileiro" },
  { value: "ro", label: "Română" },
  { value: "ru", label: "Русский" },
  { value: "sk", label: "Slovenčina" },
  { value: "sl", label: "Slovenščina" },
  { value: "sv", label: "Svensk" },
  { value: "tr", label: "Türkçe" },
  { value: "zh-Hans", label: "简体中文" },
  { value: "zh-Hant", label: "繁體中文" },
  { value: "et", label: "Eesti" },
  { value: "Gaelic", label: "Gaeilge" },
  { value: "lv", label: "Latviešu" },
  { value: "lt", label: "Lietuvių k." },
  { value: "de-LU", label: "Lëtzebuergesch" },
  { value: "mt", label: "Malti" },
  { value: "mi", label: "Māori" },
  { value: "is", label: "Íslenska" },
]

export type FileTypeOptions = {
  value: "jpeg" | "standardpdf" | "searchablepdf" | "docx" | "txt"
  label: string
  description?: string
}

export type RegularFileTypeOptions = {
  value: "jpeg" | "standardpdf"
  label: string
  description?: string
}

export type ProFileTypeOptions = {
  value: "searchablepdf" | "docx" | "txt"
  label: string
  description?: string
}
