import tokens from "@veneer/tokens"
import styled from "styled-components"

export const ProTag = styled.div<{ pro: string; tag: string }>`
  display: flex;
  align-items: baseline;
  display: ${(props) =>
    props.pro === "Sharepoint" || props.pro === "OneDrive For Business"
      ? "flex"
      : "none"};
  &:after {
    content: "${(props) => props.tag}";
    border-radius: 12px;
    padding: 2px 10px 0px 10px;
    background: ${tokens.colorGray3};
  }
`
