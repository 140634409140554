import { Card } from "@veneer/core"
import tokens from "@veneer/tokens"
import styled from "styled-components"

export const CardContentContainer = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;
  gap: 24px;
  overflow: hidden;
  @media screen and (max-width: ${tokens.mdMin}) {
    flex-direction: column;
    align-items: flex-start;
    padding-left: 24px;
    padding-right: 24px;
  }
`

export const StyledCard = styled(Card)`
  padding: 24px 0;
  background-color: white !important;
  margin: 10px 30px;
  border-color: transparent;
  border-radius: 16px;
  border-style: solid;
  border-width: 1px;
  box-sizing: inherit;
  color: rgb(33, 33, 33);
  background-color: rgb(255, 255, 255);
  box-shadow: rgba(33, 33, 33, 0.2) 0px 1px 4px 1px;
  outline: transparent solid 2px;
  outline-offset: 2px;
  @media screen and (max-width: ${tokens.mdMin}) {
    padding-bottom: 0px;
  }
`

export const LineContainer = styled.div`
  border: 1.5px solid #d9d9d9;
  @media screen and (max-width: ${tokens.mdMin}) {
    width: 100%;
  }
`
