import { JarvisAuthProvider } from "@jarvis/web-http"
import {
  Button,
  CustomImage,
  IconLock,
  IconMinusCircle,
  IconPencil,
  IconPlusCircle,
  IconTrash,
  IconWarningAlt,
  SortTypes,
  Table,
  Tooltip,
  useToast,
} from "@veneer/core"
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useHistory, useParams } from "react-router-dom"
import { i18n } from "../../assets/I18n"
import { providers } from "../../data/enum/providers"
import { CapabilityItem, Site } from "../../data/schemas/connector"
import { FolderInfoItem, FolderNameResponse } from "../../data/schemas/folders"
import { RepositoryConfig, ShortcutItem } from "../../data/schemas/shortcut"
import { uiData } from "../../helpers/dataCollection"
import { fileTypeHandler } from "../../helpers/fileTypeHelper"
import { resolveCapabilitiesId } from "../../helpers/resolveCapabilitiesId"
import { resolveFolderIds } from "../../helpers/resolveFolderIds"
import { sort, tableI18n } from "../../helpers/table"
import useTenantApi from "../../hooks/api/useTenantApi"
import CustomSearch from "../CustomSearch"
import DialogModal from "../DialogModal"
import Loader from "../Loader"
import MaxDestinationModal from "../MaxDestinationModal"
import {
  MainContainer,
  ReauthenticateAccountText,
  ScanDestinationsItemsCloudContainer,
  ShortcutActionsContainer,
  ShortcutDestinationItemContainer,
  ShortcutDestinationItemText,
  ShortcutName,
  StyledTitleIcon,
  StyledWarningContainer,
  TopContentContainer,
  UpdatePathText,
} from "./styles"

type ScanToDestinationProps = {
  stack: number
  authProvider: JarvisAuthProvider
  deviceUuid: string
  imgSrc: string
  title: string
  subtitle: string
  destinationType: string
  isLoading: boolean
  tableData: any
  onDeleteShortcut: { (value: boolean): void }
  shortcutDeletedState: boolean
  tab: number | string
  disabled: boolean
  sendUiEvent: { (uiDataObj: object): void }
  capabilities?: CapabilityItem[]
  shortcut?: ShortcutItem[]
  cloudId?: string
  folderNameItems?: FolderNameResponse
  siteItem?: Site[]
  totalDeviceShortcuts: number
}

type TableSort = {
  orderBy: string
  orderType: SortTypes
}

const ScanToDestination = (props: ScanToDestinationProps) => {
  const history = useHistory()
  const { base, cloudId } = useParams()
  const { deleteShortcut, isLoading: deletingShortcutLoading } = useTenantApi(
    props.stack,
    props.authProvider
  )

  const { addToast } = useToast()

  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [showMaxDestinationModal, setShowMaxDestinationModal] = useState(false)
  const [currentShortcut, setCurrentShortcut] = useState<ShortcutItem>()
  const [searchValue, setSearchValue] = useState<string>("")

  let listOfCapabilitiesIds: string[] = []
  let listOfInvalidFolderIds: string[] = []
  let listOfFolderNameItems: FolderInfoItem[] = []
  let listOfSiteItems: Site[] = []

  // Regex to test if root folder is equal to UUID V4 that is used by providers
  const rootFolderRegex =
    /^[0-9A-F]{8}-[0-9A-F]{4}-[4][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i

  const shortcutActions = (shortcut: ShortcutItem, disabled = false) => (
    <ShortcutActionsContainer id={`${shortcut.id}-action-container`}>
      <IconPencil
        color={disabled ? "colorGray4" : "colorHpBlue6"}
        size={26}
        // @ts-ignore https://github.azc.ext.hp.com/veneer/veneer/issues/3714
        onClick={() => {
          history.push(
            `${base}/${cloudId}/scan-destinations/${shortcut.id}/${props.destinationType}/edit`,
            { state: { cloudId } }
          )
        }}
      />
      <IconTrash
        color={disabled ? "colorGray4" : "colorHpBlue6"}
        size={26}
        // @ts-ignore https://github.azc.ext.hp.com/veneer/veneer/issues/3714
        onClick={() => {
          setCurrentShortcut(shortcut)
          setShowDeleteModal(true)
        }}
      />
    </ShortcutActionsContainer>
  )

  const shortcutDestinations = (
    shortcut: ShortcutItem,
    tab: number | string,
    disabled: boolean,
    listOfCapabilitiesIds: string[],
    listOfInvalidFolderIds: string[]
  ) => (
    <div>
      {tab === 0
        ? shortcutDestinationsEmail(shortcut, disabled)
        : shortcutDestinationsCloud(
            shortcut,
            disabled,
            listOfCapabilitiesIds,
            listOfInvalidFolderIds
          )}
    </div>
  )

  const shortcutDestinationsEmail = (
    shortcut: ShortcutItem,
    disabled = false
  ) => (
    <ShortcutDestinationItemContainer
      id={`${shortcut?.id}-item-container`}
      onClick={() => {
        setCurrentShortcut(shortcut)
      }}
    >
      <ShortcutDestinationItemText disabled={disabled}>
        {shortcut?.smartTask?.smartTaskConfig?.email.tos.join(", ")}
      </ShortcutDestinationItemText>
    </ShortcutDestinationItemContainer>
  )

  const shortcutDestinationsCloud = (
    shortcut: ShortcutItem,
    disabled = false,
    listOfCapabilitiesIds: string[],
    listOfInvalidFolderIds: string[]
  ) => (
    <ScanDestinationsItemsCloudContainer id="scan-destinations-cloud-container">
      {shortcut?.smartTask?.smartTaskConfig?.repositories.map(
        (item: RepositoryConfig) => {
          const unauthenticatedAccount =
            listOfCapabilitiesIds.length &&
            !listOfCapabilitiesIds.includes(item.connectorId)
          const invalidFolder =
            listOfInvalidFolderIds.length &&
            item.folderList &&
            item.folderList.some((element) =>
              listOfInvalidFolderIds.includes(element.folderId)
            )
          let folderName = "HP Smart"
          let displayName = "HP Smart"

          if (
            listOfFolderNameItems.find(
              (folder) =>
                folder.folderId.split("__")[0] ===
                item.connectorId.split("__")[0]
            )
          ) {
            if (item.folderList) {
              listOfFolderNameItems?.forEach((folder) => {
                if (
                  item.folderList &&
                  item.folderList[0].folderId === folder.folderId
                ) {
                  folderName = rootFolderRegex.test(folder?.folderName)
                    ? item.connectorName
                    : folder.folderName
                }
              })
            } else if (item.sites) {
              listOfSiteItems?.forEach((site) => {
                if (item.sites && item.sites[0].siteID === site.id) {
                  displayName = site.displayName
                }
              })
            }
          }

          return (
            <ShortcutDestinationItemContainer
              key={`${item.connectorId}-item-container`}
              id={`${shortcut?.id}-item-container`}
              onClick={() => {
                setCurrentShortcut(shortcut)
              }}
            >
              <CustomImage
                src={providers[item.type].icon.toString()}
                size={24}
                disabled={disabled}
                aria-label={`custom ${providers[item.type].name} image`}
              />
              <ShortcutDestinationItemText disabled={disabled}>
                <div>{item.connectorName}</div>
                <div>/</div>
                <div>
                  {unauthenticatedAccount || invalidFolder
                    ? ""
                    : folderName || displayName}
                </div>
              </ShortcutDestinationItemText>
              <StyledWarningContainer>
                {unauthenticatedAccount ? (
                  <>
                    <ReauthenticateAccountText>--</ReauthenticateAccountText>
                    <Tooltip
                      arrow
                      content={i18n.assetsProvider.getText(
                        "pages.ScanDestinations.tooltip.unauthorizedAccount"
                      )}
                      placement="bottom"
                      portal
                    >
                      <IconMinusCircle color="colorRed6" filled />
                    </Tooltip>
                  </>
                ) : invalidFolder ? (
                  <>
                    <UpdatePathText>Hp Smart</UpdatePathText>
                    <Tooltip
                      arrow
                      content={i18n.assetsProvider.getText(
                        "pages.ScanDestinations.tooltip.folderRemovedIconTooltip"
                      )}
                      placement="bottom"
                      portal
                    >
                      <IconWarningAlt color="colorOrange6" filled />
                    </Tooltip>
                  </>
                ) : null}
              </StyledWarningContainer>
            </ShortcutDestinationItemContainer>
          )
        }
      )}
    </ScanDestinationsItemsCloudContainer>
  )

  const createTableItems = (tableData: ShortcutItem[]) => {
    if (props.capabilities) {
      listOfCapabilitiesIds = resolveCapabilitiesId(props.capabilities)
    }

    if (props.folderNameItems) {
      listOfInvalidFolderIds = resolveFolderIds(props.folderNameItems)
    }

    if (props.folderNameItems?.folderInfoList !== undefined) {
      listOfFolderNameItems = props.folderNameItems.folderInfoList
    }

    if (props.siteItem !== undefined) {
      listOfSiteItems = props.siteItem
    }

    const tableDataItems = tableData.map((shortcut: ShortcutItem) => ({
      searchableName: shortcut?.smartTask?.jobName,
      searchableTos: shortcut?.smartTask?.smartTaskConfig?.email?.tos.map(
        (to: string) => to.toLocaleLowerCase()
      ),
      name: (
        <ShortcutName>
          {shortcut.pin ? (
            <IconLock filled customStyle={{ alignSelf: "center" }} />
          ) : null}{" "}
          {shortcut?.smartTask?.jobName}
        </ShortcutName>
      ),
      destinations: shortcutDestinations(
        shortcut,
        props.tab,
        props.disabled,
        listOfCapabilitiesIds,
        listOfInvalidFolderIds
      ),
      filetype: fileTypeHandler(shortcut),
      actions: shortcutActions(shortcut),
    }))
    return tableDataItems
  }

  const tableDataMemoized = useMemo(() => {
    return createTableItems(props.tableData)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.tab, props.tableData, listOfFolderNameItems, listOfSiteItems])

  const [filteredTableData, setFilteredTableData] = useState(tableDataMemoized)
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(6)
  const [orderType, setOrderType] = useState("ascending")

  const handlePageChange = (page: any) => {
    setCurrentPage(page)
  }

  const handlePageSizeChange = (_: any, option: any) => {
    setPageSize(option.value)
  }

  const transformFunction = (value: any) => {
    return value?.searchableName.toLowerCase()
  }

  const sortedData = sort(filteredTableData as [], orderType, transformFunction)

  const pagedData = sortedData.slice(
    (currentPage - 1) * pageSize,
    (currentPage - 1) * pageSize + pageSize
  )

  const initialTableSort = useRef<TableSort>({
    orderBy: "name",
    orderType: "ascending",
  })

  const onPlusButtonClick = () => {
    if (props?.totalDeviceShortcuts >= 100) {
      setShowMaxDestinationModal(true)
      return
    }

    history.push(
      `${base}/${cloudId}/scan-destinations/${props.destinationType}/add`,
      {
        state: {
          deviceUuid: props.deviceUuid,
          cloudId,
        },
      }
    )

    props.sendUiEvent({
      ...uiData.ScanDestinationsTabs.screenScanDestTabs,
      screenMode:
        props.destinationType === "cloud" ? "CloudManage" : "EmailManage",
    })

    props.sendUiEvent({
      ...uiData.ScanDestinationsTabs.btnCreateNew,
      screenMode:
        props.destinationType === "cloud" ? "CloudManage" : "EmailManage",
    })

    props.destinationType === "cloud"
      ? props.sendUiEvent(uiData.CreationForm.screenScanCloud)
      : props.sendUiEvent(uiData.CreationForm.screenScanEmail)
  }

  const onDeleteButtonClick = async () => {
    try {
      await deleteShortcut(currentShortcut?.id)
      props.onDeleteShortcut(true)
      addToast({
        id: "smb-scan-destinations-delete-success",
        type: "positive",
        text: i18n.assetsProvider.getText(
          "pages.ScanDestinations.toasts.saveChangesSuccess"
        ),
      })
    } catch (error) {
      addToast({
        id: "smb-scan-destinations-delete-error",
        type: "negative",
        text: i18n.assetsProvider.getText(
          "pages.ScanDestinations.toasts.saveChangesError"
        ),
      })
    } finally {
      setShowDeleteModal(false)
      setCurrentShortcut(undefined)
    }
  }

  const handleChange = (value?: string) => {
    setSearchValue(value)
  }

  useEffect(() => {
    const { tab } = props
    if (!searchValue) {
      setFilteredTableData(tableDataMemoized)
      return
    }
    setFilteredTableData(
      tab === 0
        ? handleSearchEmailTab(searchValue, tableDataMemoized)
        : handleSearchCloudTab(searchValue, tableDataMemoized)
    )
    setCurrentPage(1)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue])

  const handleClear = useCallback(() => {
    setFilteredTableData(tableDataMemoized)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableDataMemoized])

  const handleSubmit = useCallback(() => {
    const { tab } = props
    if (!searchValue) {
      setFilteredTableData(tableDataMemoized)
      return
    }
    setFilteredTableData(
      tab === 0
        ? handleSearchEmailTab(searchValue, tableDataMemoized)
        : handleSearchCloudTab(searchValue, tableDataMemoized)
    )
    setCurrentPage(1)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue, props.tab, tableDataMemoized])

  const handleSearchEmailTab = (value: string, tableData: any[]) => {
    return tableData.filter((row) => {
      return (
        row.searchableName.toLowerCase().includes(value.toLowerCase()) ||
        row.searchableTos.some((to: string) =>
          to.includes(value.toLocaleLowerCase())
        )
      )
    })
  }

  const handleSearchCloudTab = (value: string, tableData: any[]) => {
    return tableData.filter((row) =>
      row.searchableName.toLowerCase().includes(value.toLowerCase())
    )
  }

  const handleSort = (_: any, { type }) => {
    setOrderType(type)
  }

  return (
    <>
      <MainContainer id="scan-to-destination-container">
        <TopContentContainer id="top-content-container">
          <CustomSearch
            searchLabel={
              props.destinationType === "email"
                ? i18n.assetsProvider.getText("common.searchByScanName")
                : i18n.assetsProvider.getText("common.searchByShortcutName")
            }
            onChange={handleChange}
            onClear={handleClear}
            onSubmitHandler={handleSubmit}
          />

          <Button
            id="create-new-button"
            leadingIcon={<IconPlusCircle color="colorWhite" filled />}
            onClick={onPlusButtonClick}
            disabled={props.disabled}
          >
            {props.destinationType === "email"
              ? i18n.assetsProvider.getText("common.createNewEmail")
              : i18n.assetsProvider.getText("common.createNewCloud")}
          </Button>
        </TopContentContainer>
        {props.isLoading ? (
          <>
            <div style={{ marginTop: "50px" }}>
              <Loader showMessage={true}></Loader>
            </div>
          </>
        ) : (
          <>
            <Table
              className={props.disabled ? "disabled" : ""}
              columns={[
                {
                  id: "name",
                  label:
                    props.destinationType === "email"
                      ? i18n.assetsProvider.getText(
                          "pages.ScanDestinations.table.contactName"
                        )
                      : i18n.assetsProvider.getText(
                          "pages.ScanDestinations.table.shortcutNameLabel"
                        ),
                },
                {
                  id: "destinations",
                  label:
                    props.destinationType === "email"
                      ? i18n.assetsProvider.getText(
                          "pages.ScanDestinations.table.recipients"
                        )
                      : i18n.assetsProvider.getText(
                          "pages.ScanDestinations.table.details"
                        ),
                  sortable: false,
                },
                {
                  id: "filetype",
                  label: i18n.assetsProvider.getText(
                    "pages.ShortcutCreation.settingsSection.typeTitle"
                  ),
                  sortable: false,
                },
                { id: "actions", label: "" },
              ]}
              data={pagedData}
              loading={props.isLoading}
              loadingDataLength={3}
              onSort={handleSort}
              preferences={{
                sortBy: {
                  id: initialTableSort.current.orderBy,
                  type: initialTableSort.current.orderType,
                },
              }}
              i18n={{
                ...tableI18n,
                noItems:
                  props.tableData.length === 0
                    ? props.destinationType === "email"
                      ? i18n.assetsProvider.getText(
                          "pages.ScanDestinations.table.emptyTableEmail"
                        )
                      : i18n.assetsProvider.getText(
                          "pages.ScanDestinations.table.emptyTableCloud"
                        )
                    : i18n.assetsProvider.getText("common.noResultsFound"),
              }}
              pagination={
                filteredTableData.length
                  ? {
                      currentPage,
                      onPageChange: handlePageChange,
                      onPageSizeChange: handlePageSizeChange,
                      pageSize,
                      pageSizeOptions: [
                        { value: 2 },
                        { value: 4 },
                        { value: 6 },
                      ],
                      totalItems: filteredTableData.length,
                    }
                  : undefined
              }
            />
          </>
        )}
      </MainContainer>
      <DialogModal
        show={showDeleteModal}
        titleIcon={<StyledTitleIcon filled size={46} />}
        title={i18n.assetsProvider.getText(
          props.destinationType === "email"
            ? i18n.assetsProvider.getText(
                "pages.ScanDestinations.dialogues.removeScanDestTitle"
              )
            : i18n.assetsProvider.getText(
                "pages.ScanDestinations.dialogues.removeScanDestCloudTitle"
              )
        )}
        description={i18n.assetsProvider.getText(
          props.destinationType === "email"
            ? i18n.assetsProvider.getText(
                "pages.ScanDestinations.dialogues.removeScanDestDescription"
              )
            : i18n.assetsProvider.getText(
                "pages.ScanDestinations.dialogues.removeScanDestCloudDescription"
              )
        )}
        isLoading={deletingShortcutLoading}
        buttons={{
          firstLabel:
            props.destinationType === "email"
              ? i18n.assetsProvider.getText("common.delete")
              : i18n.assetsProvider.getText("common.remove"),
          firstColorScheme: "negative",
          firstFunction: onDeleteButtonClick,
          secondLabel: i18n.assetsProvider.getText("common.cancel"),
          secondFunction: () => {
            setCurrentShortcut(undefined)
            setShowDeleteModal(false)
          },
        }}
        onClose={() => setShowDeleteModal(false)}
      />
      <MaxDestinationModal
        show={showMaxDestinationModal}
        onClose={() => setShowMaxDestinationModal(false)}
        destinationType={props.destinationType}
      />
    </>
  )
}

export default ScanToDestination
