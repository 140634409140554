import { Button } from "@veneer/core"
import tokens from "@veneer/tokens"
import React, { Ref } from "react"
import { MainContainer, StyledFooter } from "./styles"

export type ButtonProps = {
  appearance?: "ghost" | "primary" | "secondary"
  colorScheme?: "default" | "positive" | "negative" | "warning"
  text: string
  disabled?: boolean
  loading?: boolean
  onClick?: { (): void }
}

type FooterContentProps = {
  buttons: ButtonProps[]
}

type FooterProps = {
  content: React.ReactNode
  ref?: Ref<"footer">
}

const Footer = React.forwardRef((props: FooterProps, ref: Ref<"footer">) => (
  <StyledFooter
    id="footer"
    mainContent={{
      trailingArea: props.content,
    }}
    appearance="minimalcentered"
    ref={ref}
    customStyle={{
      height: "72px",
      paddingRight: "23px",
      paddingBottom: "11px",
      paddingTop: "11px",
      backgroundColor: tokens.colorGray0,
      alignItems: "end",
    }}
  />
))

const FooterContent = ({ buttons }: FooterContentProps) => (
  <MainContainer id="footer-main-container">
    {buttons.map((button, index) => (
      <Button
        id={`${button.text}-button`}
        key={`${button.text.replace(/\s+/g, "-").toLowerCase()}-btn${index}`}
        appearance={button.appearance}
        disabled={Boolean(button.disabled)}
        loading={Boolean(button.loading)}
        onClick={button.onClick}
      >
        {button.text}
      </Button>
    ))}
  </MainContainer>
)

export const CustomFooter = React.forwardRef(
  (props: FooterContentProps, ref: Ref<"footer">) => (
    <Footer content={FooterContent(props)} ref={ref} />
  )
)

export default CustomFooter
