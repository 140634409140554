import React from "react"
import {
  CustomDescription,
  CustomTitleText,
  DisconnectAccModal,
} from "./styles"
import { i18n } from "../../assets/I18n"

type DisconnectAccountModalProps = {
  show: boolean
  onClose: { (): void }
}

const DisconnectAccountModal = ({
  show,
  onClose,
}: DisconnectAccountModalProps) => {
  return (
    <DisconnectAccModal
      id="disconnect-acc-modal-container"
      closeButton
      onClose={onClose}
      show={show}
    >
      <CustomTitleText
        className="title-small"
        aria-label="disconnect-modal-header"
      >
        {i18n.assetsProvider.getText("pages.ShortcutCreation.cloudSection.verifiedAccountsPanel.disconnectAccountModalTitle")}
      </CustomTitleText>
      <CustomDescription
        className="body-large"
        aria-label="disconnect-modal-description"
      >
        {i18n.assetsProvider.getText("pages.ShortcutCreation.cloudSection.verifiedAccountsPanel.disconnectAccountModalDescription")}
      </CustomDescription>
    </DisconnectAccModal>
  )
}

export default DisconnectAccountModal
