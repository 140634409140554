import React, { ReactElement, useEffect, useState } from "react"
import { createPortal } from "react-dom"
import CustomFooter, { ButtonProps } from "../CustomFooter"
import CustomHeader from "../CustomHeader"
import { ContentContainer, MainContainer } from "./styles"

type OverlayProps = {
  active: boolean
  headerIcon: ReactElement
  headerTitle: string
  footerButtons: ButtonProps[]
  children: ReactElement[]
  container: HTMLElement
}

const Overlay = (props: OverlayProps) => {
  const [fade, setFade] = useState("fadeIn")
  const [show, setShow] = useState(props.active)

  const onFadeEnd = () => {
    if (fade === "fadeOut") {
      setShow(false)
    }
  }

  useEffect(() => {
    setFade(props.active ? "fadeIn" : "fadeOut")
    if (props.active) {
      setShow(true)
    }
  }, [props.active])

  return createPortal(
    <MainContainer
      id="overlay-main-container"
      show={show}
      fade={fade}
      onAnimationEnd={onFadeEnd}
    >
      <CustomHeader icon={props.headerIcon} title={props.headerTitle} />
      <ContentContainer id="overlay-content-container">
        {props.active && props.children}
      </ContentContainer>
      <CustomFooter buttons={props.footerButtons} />
    </MainContainer>,
    props.container
  )
}

export default Overlay
