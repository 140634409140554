import { Card, Password, PasswordProps, TextBox } from "@veneer/core"
import tokens from "@veneer/tokens"
import styled from "styled-components"

export const ScanDestionationSettings = styled.div`
  border-bottom: 1px solid var(--Light-Stroke, #dbdbdb);
  padding: 12px 16px;
`

export const NameTitle = styled.h6`
  display: flex;
  align-items: center;
  @media (max-width: ${tokens.lgMin}) {
    padding-top: 4px;
    padding-bottom: 4px;
  }
  @media (max-width: ${tokens.smMin}) {
    padding-top: 8px;
    padding-bottom: 8px;
  }
`
export const ShortcutNameContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
`

export const NameInput = styled(TextBox)`
  -webkit-box-sizing: border-box !important;
  -moz-box-sizing: border-box !important;
  box-sizing: border-box !important;

  #shortcut-name-input-label {
    @media (max-width: ${tokens.smMin}) {
      width: 80%;
      max-width: 80%;
    }

    @media (max-width: 360px) {
      width: 70%;
    }
  }
`

export const NameDescription = styled.div`
  display: flex;
  padding-top: 8px;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
  padding-bottom: 24px;
`

export const NameDescriptionText = styled.p`
  color: var(--Light-Foreground, #212121);
  font-size: 14px;
  line-height: 20px;
`

export const SecurityPinContainer = styled.div`
  padding-top: 24px;
  border-top: 1px solid var(--Light-Stroke, ${tokens.colorGray3});
  @media (max-width: ${tokens.mdMin}) {
    padding-left: 0;

    h6 {
      width: max-content;
    }
  }
`

export const SecurityPinInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  opacity: ${({ isDimmed }) => isDimmed && "0.5"};
`

export const PinInput = styled<PasswordProps>(Password)`
  margin-right: 20px;
  margin-top: 13px;
`

export const ToggleContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const ToggleHeader = styled.div`
  margin-left: 52px;
`

export const MainContainer = styled(Card)`
  display: grid;
  grid-template-rows: auto auto;
  margin-bottom: 24px;
  box-shadow: 0px 1px 4px 1px rgba(33, 33, 33, 0.2);
`
