import { Modal } from "@veneer/core"
import styled from "styled-components"

export const StyledModal = styled(Modal)`
  div.vn-modal--content {
    width: 460px;
  }
  z-index: 1400 !important;
`
export const SubTitle = styled.p`
  padding-bottom: 15px;
`
export const ButtonGroup = styled.div`
  display: flex !important;
  justify-content: flex-end !important;
  button {
    margin-right: 10px;
  }
`
export const SelectContainer = styled.div`
  font-weight: 400;
`
