const openSigninWindow = (url: string) => {
  return window.open(
    url,
    "AuthorizationPopup",
    "left=450,top=100,height=570,width=520,scrollbars=yes,status=yes,location=yes"
  )
}

const handleSigninMessageEvent = (
  event: MessageEvent,
  postSigninCallback: { (): void }
) => {
  if (
    event?.data.type === "signin" &&
    event?.origin === window.location.origin
  ) {
    postSigninCallback()
  }
}

export { openSigninWindow, handleSigninMessageEvent }
