import { IconInfo, Toggle } from "@veneer/core"
import { useFlags } from "launchdarkly-react-client-sdk"
import React from "react"
import { useLocation } from "react-router-dom"
import { i18n } from "../../assets/I18n"
import {
  MainContainer,
  NameDescription,
  NameDescriptionText,
  NameInput,
  NameTitle,
  PinInput,
  ScanDestionationSettings,
  SecurityPinContainer,
  SecurityPinInputContainer,
  ShortcutNameContent,
  ToggleContainer,
  ToggleHeader,
} from "./styles"

type ScanDestinationSettingsProps = {
  onChange: { (value: string): void }
  onChangeSecurityPinAdded: { (value: boolean): void }
  onChangeSecurityPin: { (value: string): void }
  onChangeSecurityPinConfirmation: { (value: string): void }
  onPinBlur: { (): void }
  value: string
  error: boolean
  helperText: string
  entitled: boolean
  isSecurityPinToggleActive: boolean
  securityPin: string
  pinError: string
  hasSecurityPinErrors: boolean
  securityPinHelperText?: string
  securityPinConfirmation: string
  securityPinMaxLength: number
  disabled: boolean
}

const ScanDestinationSettings = (props: ScanDestinationSettingsProps) => {
  const { smbPinProtected } = useFlags()
  const location = useLocation()
  const destinationType = location.pathname.split("/").slice(-2)[0]

  const CardContent = () => (
    <>
      <ScanDestionationSettings>
        <NameTitle>
          {destinationType === "email"
            ? i18n.assetsProvider.getText("pages.ShortcutCreation.settingsSection.settingsEmailTitle")
            : i18n.assetsProvider.getText("pages.ShortcutCreation.settingsSection.settingsCloudTitle")}
        </NameTitle>
      </ScanDestionationSettings>
      <ShortcutNameContent>
        <NameInput
          id="shortcut-name-input"
          aria-label="shortcut name input"
          label={
            destinationType === "email"
              ? i18n.assetsProvider.getText(
                  "pages.ShortcutCreation.settingsSection.inputSettingsEmailLabel"
                )
              : i18n.assetsProvider.getText(
                  "pages.ShortcutCreation.settingsSection.inputSettingsCloudLabel"
                )
          }
          value={props.value}
          error={props.error}
          helperText={props.helperText}
          onChange={props.onChange}
          required
          autoFocus
          disabled={props.disabled}
        />
        <NameDescription>
          <IconInfo filled color="colorGray5" size={24} />
          <NameDescriptionText>
            {destinationType === "email"
              ? i18n.assetsProvider.getText("pages.ShortcutCreation.settingsSection.settingsEmailDescription")
              : i18n.assetsProvider.getText("pages.ShortcutCreation.settingsSection.settingsCloudDescription")}
          </NameDescriptionText>
        </NameDescription>
        {smbPinProtected && props.entitled && (
          <SecurityPinContainer id="security-pin-container">
            <Toggle
              id="custom-toggle"
              label={i18n.assetsProvider.getText(
                "pages.ShortcutCreation.settingsSection.securityPin.pinToggleLabel"
              )}
              on={props.isSecurityPinToggleActive}
              onChange={props.onChangeSecurityPinAdded}
              disabled={props.disabled}
            />
            <ToggleContainer>
              <ToggleHeader>
                <p className="caption">
                  {i18n.assetsProvider.getText(
                    "pages.ShortcutCreation.settingsSection.securityPin.description"
                  )}
                </p>
              </ToggleHeader>
            </ToggleContainer>
            {props.isSecurityPinToggleActive && (
              <SecurityPinInputContainer id="security-pin-input-container">
                <PinInput
                  id="pin-input"
                  type="number"
                  label={i18n.assetsProvider.getText(
                    "pages.ShortcutCreation.settingsSection.securityPin.label"
                  )}
                  value={props.securityPin}
                  maxLength={props.securityPinMaxLength}
                  helperText={i18n.assetsProvider.getText(
                    "pages.ShortcutCreation.settingsSection.securityPin.characterCountEightDigitsDisclaimer"
                  )}
                  onChange={props.onChangeSecurityPin}
                  disabled={props.disabled}
                />
                <PinInput
                  id="pin-input-confirmation"
                  type="number"
                  label={i18n.assetsProvider.getText(
                    "pages.ShortcutCreation.settingsSection.securityPin.confirmationPlaceholder"
                  )}
                  value={props.securityPinConfirmation}
                  maxLength={props.securityPinMaxLength}
                  disabled={props.securityPin.length < 8 || props.disabled}
                  error={props.hasSecurityPinErrors}
                  helperText={props.pinError}
                  onChange={props.onChangeSecurityPinConfirmation}
                />
              </SecurityPinInputContainer>
            )}
          </SecurityPinContainer>
        )}
      </ShortcutNameContent>
    </>
  )

  return <MainContainer content={CardContent()} />
}

export default ScanDestinationSettings
