import { useEffect, useState } from "react"
import { i18n } from "../../assets/I18n"

const useConnectorValidation = () => {
  const [newAccountName, setNewAccountName] = useState("")
  const [newAccountNameError, setNewAccountNameError] = useState("")

  const isValidConnectorName =
    /^[^%#/:*?\\"<>()|{}~`!@$^&+=´]+$/.test(newAccountName) &&
    !/(^[.])|([.]$)|([[\]])/.test(newAccountName)

  useEffect(() => {
    setNewAccountNameError(
      newAccountName && !isValidConnectorName
        ? i18n.assetsProvider.getText(
            "pages.ShortcutCreation.cloudSection.connectorNameSpecialCharactersNotAllowed"
          )
        : ""
    )
  }, [newAccountName, isValidConnectorName])

  return {
    newAccountName,
    setNewAccountName,
    newAccountNameError,
  }
}

export default useConnectorValidation
