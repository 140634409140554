function defaultComparator(a: object, b: object) {
  if (b < a) {
    return -1
  }
  if (b > a) {
    return 1
  }
  return 0
}

function sort(
  array: [],
  orderType: string,
  transformFunction?: (a: any) => any,
  customComparator?: (a: any, b: any) => number
) {
  const comparator = customComparator ? customComparator : defaultComparator
  return array.sort((a: any, b: any) => {
    let aValue = a
    let bValue = b
    if (transformFunction) {
      aValue = transformFunction(a)
      bValue = transformFunction(b)
    }
    const value =
      orderType === "descending"
        ? comparator(aValue, bValue)
        : -comparator(aValue, bValue)
    return value
  })
}

// Unfortunately the Veneer components require the whole i18n object even if we don't use
// most of them. This object was created so we don't have to repeat our selves. We can just
// use destructuring to fill the fields we need
const tableI18n = {
  actionButton: "",
  cancelButton: "",
  clearAllFilters: "",
  columnOptions: "",
  columnResizeTooltip: "",
  deselectAllItems: "",
  downButton: "",
  itemSelected: "",
  itemsSelected: "",
  noItems: "",
  resetToDefault: "",
  saveButton: "",
  selectAllItems: "",
  selectAllPageItems: "",
  sortedAscending: "",
  sortedDescending: "",
  upButton: "",
}

export { sort, tableI18n }
