import { JarvisAuthProvider } from "@jarvis/web-http"
import {
  ContextualMenu,
  ContextualMenuInterface,
  CustomImage,
  IconEllipsis,
  IconPencil,
  IconPlusCircle,
  IconWarning,
  MenuItem,
  MenuList,
  OptionValue,
  SideBar,
} from "@veneer/core"
import React, { Dispatch, SetStateAction, useEffect, useState } from "react"
import { i18n } from "../../assets/I18n"
import { ProviderAction } from "../../data/enum/cloudTable"
import { providers } from "../../data/enum/providers"
import { CapabilityItem, ConnectorData } from "../../data/schemas/connector"
import DisconnectAccountModal from "../DisconnectAccountModal"
import Loader from "../Loader"
import ProTooltip from "../ProTooltip"
import {
  AlignProTooltip,
  ConnectorItemContainer,
  ConnectorItemContent,
  ConnectorItemHeader,
  ConnectorItemName,
  ConnectorLabelBar,
  CustomErrorDescription,
  CustomErrorText,
  CustomErrorTitle,
  DisconnectAccButton,
  ErrorWidgetContainer,
  IconEllipsisContainer,
  NoAccountsContainer,
  ProviderDescription,
  ProviderText,
  SidePanelContainer,
  WarningIconContainer,
} from "./styles"

type AuthAccountsPanelProps = {
  stack: number
  authProvider: JarvisAuthProvider
  show: boolean
  setShow: Dispatch<SetStateAction<boolean>>
  authenticatedConnectorItems: CapabilityItem[]
  selectedProviders: ConnectorData[]
  isLoading: boolean
  onRetry: { (): void }
  onClose?: { (): void }
  onAddConnectorItem?: { (provider: CapabilityItem): void }
  providerSelected: CapabilityItem
  onEditConnectorItem?: { (provider: CapabilityItem): void }
}

type ConnectorItemProps = {
  connectorName: string
  connectorType: string
  isLastItem: boolean
  onAddProvider: { (providerName: String): void }
  onEditProvider: { (providerName: String): void }
}

const ConnectorItem = ({
  connectorName,
  connectorType,
  onAddProvider,
  onEditProvider,
  isLastItem,
}: ConnectorItemProps) => {
  const onHandleMenu: ContextualMenuInterface<OptionValue>["onClick"] = (
    _,
    option
  ) => {
    switch (option.value) {
      case ProviderAction.ChangeFolderPath:
        onAddProvider(connectorName)
        break
      case ProviderAction.EditAccountName:
        onEditProvider(connectorName)
        break
      default:
        break
    }
  }
  return (
    <>
      <ConnectorItemContainer aria-label="connector-item-container">
        <ConnectorItemContent aria-label="connector-item-content">
          <ConnectorLabelBar aria-label="connector-label-bar">
            <CustomImage
              id="provider-icon"
              src={providers[`${connectorType}`].icon.toString()}
              size={18}
              alt={connectorType}
              aria-label="provider-icon"
            />
            <p id="provider-text" aria-label="provider-text">
              {providers[`auth${connectorType}`].name}
            </p>
          </ConnectorLabelBar>
          <ConnectorItemHeader aria-label="connector-item-header">
            <ConnectorItemName
              className="label"
              aria-label="connector-item-name"
            >
              {connectorName}
            </ConnectorItemName>
          </ConnectorItemHeader>
        </ConnectorItemContent>
        <AlignProTooltip aria-label="align-pro-tooltip">
          <ProTooltip
            type={providers[`auth${connectorType}`].name}
            aria-label="pro-tooltip"
          />
        </AlignProTooltip>
        <ContextualMenu
          onClick={onHandleMenu}
          anchorNode={
            <IconEllipsisContainer aria-label="icon-ellipsis-container">
              <IconEllipsis
                id="elipsis-icon"
                size={36}
                aria-label="icon-ellipsis"
                customStyle={{ color: "#0076AD !important" }}
              />
            </IconEllipsisContainer>
          }
          placement={isLastItem ? "top-end" : "bottom-end"}
          aria-label="contextual-menu"
        >
          <MenuList>
            <MenuItem
              value={ProviderAction.ChangeFolderPath}
              label={i18n.assetsProvider.getText("pages.ShortcutCreation.cloudSection.folderTreeTitle")}
              leadingArea={(<IconPlusCircle />) as unknown as React.ElementType}
              aria-label="menu-item-choose-location"
            />
            <MenuItem
              value={ProviderAction.EditAccountName}
              label={i18n.assetsProvider.getText(
                "pages.ShortcutCreation.cloudSection.tableActions.editAccountName"
              )}
              leadingArea={(<IconPencil />) as unknown as React.ElementType}
              aria-label="menu-item-edit-account-name"
            />
          </MenuList>
        </ContextualMenu>
      </ConnectorItemContainer>
    </>
  )
}

const AuthAccountsPanel = (props: AuthAccountsPanelProps) => {
  const [tableData, setTableData] = useState([])
  const [showDisconnectAccModal, setShowDisconnectAccModal] = useState(false)

  useEffect(() => {
    setTableData(
      props.authenticatedConnectorItems.filter(
        (provider: CapabilityItem) =>
          !props.selectedProviders.find(
            (fetchedProvider) =>
              fetchedProvider.connector.connectorId === provider.connectorId
          )
      )
    )
  }, [props.authenticatedConnectorItems, props.selectedProviders])

  const loadConnectorItems = () => {
    if (props.isLoading) {
      return (
        <ErrorWidgetContainer>
          <Loader />
        </ErrorWidgetContainer>
      )
    }

    if (tableData === undefined) {
      return (
        <NoAccountsContainer>
          <WarningIconContainer>
            <IconWarning id="warning-icon" size={24} />
          </WarningIconContainer>
          <CustomErrorText className="label">
            {i18n.assetsProvider.getText("pages.ShortcutCreation.cloudSection.verifiedAccountsPanel.error")}
          </CustomErrorText>
        </NoAccountsContainer>
      )
    }

    if (tableData?.length === 0) {
      return (
        <NoAccountsContainer>
          <WarningIconContainer>
            <IconWarning id="warning-icon" size={24} />
          </WarningIconContainer>
          <CustomErrorTitle>
            {i18n.assetsProvider.getText("pages.ShortcutCreation.cloudSection.verifiedAccountsPanel.noAccounts")}
          </CustomErrorTitle>
          <CustomErrorDescription className="label">
            {i18n.assetsProvider.getText("pages.ShortcutCreation.cloudSection.verifiedAccountsPanel.noAccountsDescription")}
          </CustomErrorDescription>
        </NoAccountsContainer>
      )
    }

    return (
      <>
        {tableData?.map((item, index, array) => {
          return (
            <ConnectorItem
              key={`${item.connectorName}-${item.type}-connector`}
              connectorName={item.connectorName}
              connectorType={item.type}
              onAddProvider={() => props.onAddConnectorItem(item)}
              onEditProvider={() => props.onEditConnectorItem(item)}
              isLastItem={index === array.length - 1}
            />
          )
        })}
      </>
    )
  }

  return (
    <>
      <SidePanelContainer>
        <SideBar
          id="side-panel"
          content={
            <div>
              <div id="panel-content">
                <ProviderText className="title">
                {i18n.assetsProvider.getText("pages.ShortcutCreation.cloudSection.verifiedAccountsPanel.header")}
                </ProviderText>
                <ProviderDescription>
                {i18n.assetsProvider.getText("pages.ShortcutCreation.cloudSection.verifiedAccountsPanel.description")}
                </ProviderDescription>
                <DisconnectAccButton
                  onClick={() => setShowDisconnectAccModal(true)}
                >
                  {i18n.assetsProvider.getText("pages.ShortcutCreation.cloudSection.verifiedAccountsPanel.disconnectAccountButton")}
                </DisconnectAccButton>
                {loadConnectorItems()}
              </div>
            </div>
          }
          onClose={() => props.setShow(false)}
          show={props.show}
          size={416}
        />
      </SidePanelContainer>
      <DisconnectAccountModal
        show={showDisconnectAccModal}
        onClose={() => setShowDisconnectAccModal(false)}
      />
    </>
  )
}

export default AuthAccountsPanel
