import tokens from "@veneer/tokens"
import { createGlobalStyle } from "styled-components"

// TODO: We can probabaly remove font-family below when smb-printers upgrades to the new font
export const GlobalStyle = createGlobalStyle`
  * {
    font-family: ${tokens.fontTextRegular} !important;
  }

  .disabled{
    pointer-events: none;
  }

  .solutionItemVector{
    margin-bottom: 1px;
  }

  .creationFormIcon{
    width: 36px;
    height: 36px;
  }
`
