import {
  Button,
  CustomImage,
  IconFolder,
  IconGlobe,
  IconMinusCircle,
  IconTrash,
  IconWarningAlt,
  ProgressIndicator,
  Tooltip,
} from "@veneer/core"
import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react"
import IconFilePath from "../../assets/components/IconFilePath"
import { i18n } from "../../assets/I18n"
import ProTooltip from "../../components/ProTooltip"
import { ProviderAction } from "../../data/enum/cloudTable"
import { providers, ProvidersOptions } from "../../data/enum/providers"
import {
  CapabilityItem,
  ConnectorData,
  Site,
} from "../../data/schemas/connector"
import { FolderNameResponse } from "../../data/schemas/folders"
import { RepositoryConfig } from "../../data/schemas/shortcut"
import { resolveCapabilitiesId } from "../../helpers/resolveCapabilitiesId"
import { resolveFolderIds } from "../../helpers/resolveFolderIds"
import { sort, tableI18n } from "../../helpers/table"
import ErrorWidget from "../ErrorWidget"
import {
  AuthNewAccFrame,
  CapabilitiesLoader,
  ChevronContainer,
  CloudFormSelectContainer,
  CloudFormTable,
  CloudSelectDescription,
  CloudSelectLabel,
  ContextualMenuTableButton,
  ErrorContainer,
  ExistingCloudTitle,
  InvalidFolder,
  MainContainer,
  ParentBox,
  ProviderFailedText,
  ProviderFolderIconContainer,
  ProviderInfoContainer,
  ProviderInfoTypeEmailContainer,
  ProviderItemContainer,
  ProviderItemHeader,
  ProviderItemImage,
  ProviderItemName,
  ProviderSiteIconContainer,
  ProviderText,
  ReauthorizeText,
  StyledIconChevronRight,
} from "./styles"

type ButtonProps = {
  appearance?: "ghost" | "primary" | "secondary"
  colorScheme?: "default" | "positive" | "negative" | "warning"
  text: string
  disabled?: boolean
  loading?: boolean
  onClick?: { (): void }
}

type CloudDestinationSelectProps = {
  selectedCloudDestination: string
  onSelectCloudDestination: { (value: ProvidersOptions): void }
  providerOptions: ProvidersOptions[]
  isDisabled: boolean
  button: ButtonProps
}

const CloudDestinationSelect = ({ button }: CloudDestinationSelectProps) => {
  return (
    <>
      <CloudFormSelectContainer id="cloud-form-select-container">
        <CloudSelectLabel className="body">
          {i18n.assetsProvider.getText("pages.ShortcutCreation.cloudSection.addNewCloudStorageAccount")}
        </CloudSelectLabel>
        <Button
          id="cloud-form-button"
          appearance={button.appearance}
          disabled={button.disabled}
          onClick={button.onClick}
          loading={button.loading}
        >
          {button.text}
        </Button>
      </CloudFormSelectContainer>
      <CloudSelectDescription className="body">
        {i18n.assetsProvider.getText("pages.ShortcutCreation.cloudSection.addNewCloudStorageAccountDescription")}
      </CloudSelectDescription>
    </>
  )
}

type ProviderItemProps = {
  name: string
  type: string
  onSelect: { (providerName: String): void }
}

const ProviderItem = ({ name, type, onSelect }: ProviderItemProps) => {
  return (
    <ProviderItemContainer onClick={onSelect}>
      <ProviderItemImage>
        <CustomImage
          src={providers[name].icon.toString()}
          size={52}
          alt={type}
        />
      </ProviderItemImage>
      <ProviderItemHeader>
        <ProviderItemName className="subtitle-regular">{type}</ProviderItemName>
        <ProTooltip type={type} />
      </ProviderItemHeader>
      <ChevronContainer>
        <StyledIconChevronRight color="colorGray4" />
      </ChevronContainer>
    </ProviderItemContainer>
  )
}

type CloudDestinationTableProps = {
  tableData: any
  handleProviderActions: {
    (providerItem: ConnectorData, actionType: ProviderAction): void
  }
  listOfCapabilitiesIds: string[]
  listOfInvalidFolderIds: string[]
  repositoryConfigList: RepositoryConfig[]
  setTriggerAccountSignin: Dispatch<SetStateAction<boolean>>
  setCurrentProviderType: Dispatch<SetStateAction<string>>
  setNewAccountName: Dispatch<SetStateAction<string>>
  isLegacyShortcut: boolean
}

const CloudDestinationTable = (props: CloudDestinationTableProps) => {
  const [tableData, setTableData] = useState([])
  const [tableOrderType, setTableOrderType] = useState("ascending")
  const providerNameAndIcon = (
    providerType: string,
    connectorName: string,
    connectorId: string,
    listOfCapabilitiesIds: string[]
  ) => {
    return (
      <ProviderInfoContainer
        key={`provider-info-container-${providerType}-${connectorId}`}
        id={`provider-info-container-${providerType}-${connectorId}`}
      >
        <CustomImage
          id={`${providerType}-custom-image`}
          src={providers[providerType].icon.toString()}
          size={36}
        ></CustomImage>
        <ProviderInfoTypeEmailContainer
          id={`provider-info-type-email-${providerType}-container`}
        >
          {listOfCapabilitiesIds.length &&
          !listOfCapabilitiesIds.includes(connectorId) ? (
            <>
              <ProviderFailedText className="label">
                {connectorName}
              </ProviderFailedText>
              <Tooltip
                arrow
                content={i18n.assetsProvider.getText("pages.ScanDestinations.tooltip.folderRemovedIconTooltip")}
                placement="bottom"
                portal
              >
                <IconMinusCircle size={18} color="colorRed6" filled />
              </Tooltip>
            </>
          ) : (
            <ProviderText className="label">{connectorName}</ProviderText>
          )}
        </ProviderInfoTypeEmailContainer>
      </ProviderInfoContainer>
    )
  }

  const providerFolders = (
    connectorId: string,
    connectorType: string,
    connectorName: string,
    listOfCapabilitiesIds: string[],
    listOfInvalidFolderIds: string[],
    repositoryConfigList: RepositoryConfig[],
    folderName: string,
    folderId: string,
    displayName: string,
    setTriggerAccountSignin: Dispatch<SetStateAction<boolean>>,
    setCurrentProviderType: Dispatch<SetStateAction<string>>,
    setNewAccountName: Dispatch<SetStateAction<string>>
  ) => {
    const listOfShortcutItemFolderIds = []
    const listOfShortcutItemSitesIds = []

    if (repositoryConfigList.some((repository) => repository.folderList)) {
      repositoryConfigList.forEach((item) =>
        item.folderList
          ? item.folderList.forEach((val) =>
              listOfShortcutItemFolderIds.push(val.folderId)
            )
          : null
      )
    } else if (repositoryConfigList.some((repository) => repository.sites)) {
      repositoryConfigList.forEach((item) =>
        item.sites
          ? item.sites.forEach((val) =>
              listOfShortcutItemSitesIds.push(val.siteID)
            )
          : null
      )
    }

    const isLegacy =
      repositoryConfigList.map((item) => item.folderList).includes(undefined) &&
      !displayName

    return (
      <ProviderInfoContainer>
        {listOfCapabilitiesIds.length &&
        !listOfCapabilitiesIds.includes(connectorId) ? (
          <ReauthorizeText
            className="label"
            onClick={() => {
              setCurrentProviderType(connectorType)
              setNewAccountName(connectorName)
              setTriggerAccountSignin(true)
            }}
          >
            --
          </ReauthorizeText>
        ) : listOfInvalidFolderIds &&
          listOfInvalidFolderIds.length &&
          listOfShortcutItemFolderIds &&
          listOfShortcutItemFolderIds.length &&
          listOfInvalidFolderIds.includes(folderId) ? (
          <>
            <InvalidFolder className="label">
              HP Smart
              <Tooltip
                arrow
                content={i18n.assetsProvider.getText("pages.ScanDestinations.tooltip.folderRemovedIconTooltip")}
                placement="bottom"
                portal
              >
                <IconWarningAlt size={24} color="colorOrange6" filled />
              </Tooltip>
            </InvalidFolder>
          </>
        ) : (
          <>
            {(!!folderName || (isLegacy && !displayName)) && (
              <>
                <ProviderFolderIconContainer>
                  <IconFolder filled />
                </ProviderFolderIconContainer>
                <ProviderText>{folderName ?? "HP Smart"}</ProviderText>
              </>
            )}
            {displayName && (
              <>
                <ProviderSiteIconContainer>
                  <IconGlobe filled color="colorBlue3" />
                </ProviderSiteIconContainer>
                <ProviderText>{displayName}</ProviderText>
              </>
            )}
          </>
        )}
      </ProviderInfoContainer>
    )
  }

  const transformFunction = (value: ConnectorData) => {
    return value?.connector?.connectorName?.toLowerCase()
  }

  const handleSort = (_: any, { type }) => {
    setTableOrderType(type)
  }

  const createTableItems = useCallback(
    (arrayToConvert: ConnectorData[]) => {
      return arrayToConvert.map((connectorData: ConnectorData) => ({
        type: providerNameAndIcon(
          connectorData.connector?.type,
          connectorData.connector?.connectorName,
          connectorData.connector?.connectorId,
          props.listOfCapabilitiesIds
        ),
        folders: providerFolders(
          connectorData.connector?.connectorId,
          connectorData.connector?.type,
          connectorData.connector?.connectorName,
          props.listOfCapabilitiesIds,
          props.listOfInvalidFolderIds,
          props.repositoryConfigList,
          connectorData?.folder?.name,
          connectorData?.folder?.id,
          connectorData?.site?.displayName,
          props.setTriggerAccountSignin,
          props.setCurrentProviderType,
          props.setNewAccountName
        ),
        rowConfig: {
          action: (
            <ContextualMenuTableButton
              onClick={(_event, { value }) => {
                props.handleProviderActions(
                  connectorData,
                  value as ProviderAction
                )
              }}
              options={[
                {
                  value: ProviderAction.ChangeFolderPath,
                  //TODO: import the icon directly from Veneer when it is updated
                  icon: <IconFilePath />,
                  label: i18n.assetsProvider.getText("pages.ShortcutCreation.cloudSection.tableActions.changeLocation"),
                  disabled:
                    props.listOfCapabilitiesIds.length &&
                    !props.listOfCapabilitiesIds.includes(
                      connectorData.connector?.connectorId
                    ),
                },
                {
                  value: ProviderAction.RemoveAccount,
                  icon: <IconTrash />,
                  label: i18n.assetsProvider.getText("pages.ShortcutCreation.cloudSection.tableActions.removeDestination"),
                },
              ]}
              placement="bottom"
            />
          ),
        },
      }))
    },
    [props]
  )

  useEffect(() => {
    const sortedDataTable = sort(
      props.tableData,
      tableOrderType,
      transformFunction
    )
    setTableData(createTableItems(sortedDataTable))
  }, [createTableItems, props.tableData, tableOrderType])

  return (
    <>
      <ExistingCloudTitle>
        {i18n.assetsProvider.getText("pages.ShortcutCreation.cloudSection.existingCloudTitle")}
      </ExistingCloudTitle>
      <CloudFormTable
        id="cloud-form-table"
        columns={[
          {
            id: "type",
            label: i18n.assetsProvider.getText("pages.ShortcutCreation.cloudSection.tableLabels.accountName"),
          },
          {
            id: "folders",
            label: i18n.assetsProvider.getText("pages.ShortcutCreation.cloudSection.tableLabels.folder"),
            sortable: false,
          },
        ]}
        data={tableData}
        loadingDataLength={3}
        onSort={handleSort}
        preferences={{
          sortBy: {
            id: "type",
            type: "ascending",
          },
          width: [
            { columnId: "type", width: "40%" },
            { columnId: "folders", width: "60%" },
          ],
        }}
        i18n={{
          ...tableI18n,
          noItems: i18n.assetsProvider.getText(
            "pages.ShortcutCreation.cloudSection.tableNoDestinations"
          ),
        }}
      />
    </>
  )
}

type CardErrorWidgetProps = {
  onRetry: { (params?: any): any }
}

const CardErrorWidget = (props: CardErrorWidgetProps) => {
  const CardErrorContent = () => (
    <ErrorWidget onRetry={props.onRetry}></ErrorWidget>
  )
  return (
    <ErrorContainer id="error-container-card" content={CardErrorContent()} />
  )
}

type CloudFormSectionProps = {
  selectedCloudDestination: string
  onSelectCloudDestination: { (value: ProvidersOptions): void }
  isDisabled: boolean
  tableData: ConnectorData[]
  handleProviderActions: {
    (providerItem: ConnectorData, actionType: ProviderAction): void
  }
  providerOptions: ProvidersOptions[]
  errorWithCapabilities: boolean
  onRetry: { (params?: any): any }
  viewVerifiedAccounts: { (): void }
  capabilities: CapabilityItem[]
  folderNameItems: FolderNameResponse
  siteItem: Site[]
  repositoryConfigList: RepositoryConfig[]
  isLoadingTable: boolean
  isLoadingCapabilities: boolean
  setTriggerAccountSignin: Dispatch<SetStateAction<boolean>>
  setCurrentProviderType: Dispatch<SetStateAction<string>>
  setNewAccountName: Dispatch<SetStateAction<string>>
  isLegacyShortcut: boolean
  onAddNew?: { (providerName: String): void }
}

const CloudFormSection = (props: CloudFormSectionProps) => {
  const listOfCapabilitiesIds = resolveCapabilitiesId(props.capabilities)
  const listOfInvalidFolderIds = resolveFolderIds(props.folderNameItems)

  const { pathname } = window.location
  const paths = pathname.split("/").filter((entry) => entry !== "")
  const isLoadingInEditMode = paths.pop()

  const hasFolderName = useMemo(
    () =>
      !!props.folderNameItems.folderInfoList
        .map((item) => item.folderName)
        .filter((name) => name).length,
    [props.folderNameItems.folderInfoList]
  )
  const hasDisplayName = useMemo(
    () =>
      !!props.siteItem
        .map((item) => item.displayName)
        .filter((displayName) => displayName).length,
    [props.siteItem]
  )
  const hasFolderDeleted = useMemo(
    () => props.folderNameItems.folderInfoList.some((i) => i.isValid === false),
    [props.folderNameItems.folderInfoList]
  )

  return (
    <MainContainer
      id="cloud-form-section-main-container"
      content={
        <>
          {isLoadingInEditMode === "edit" && props.isLoadingCapabilities ? (
            <CapabilitiesLoader id="capabilities-loader-container">
              <ProgressIndicator />
            </CapabilitiesLoader>
          ) : props.errorWithCapabilities ? (
            <CardErrorWidget onRetry={props.onRetry} />
          ) : (
            <>
              {(props.isLegacyShortcut ||
                hasFolderName ||
                hasDisplayName ||
                hasFolderDeleted) && (
                <CloudDestinationTable
                  tableData={props.tableData}
                  handleProviderActions={props.handleProviderActions}
                  listOfCapabilitiesIds={listOfCapabilitiesIds}
                  listOfInvalidFolderIds={listOfInvalidFolderIds}
                  repositoryConfigList={props.repositoryConfigList}
                  setTriggerAccountSignin={props.setTriggerAccountSignin}
                  setCurrentProviderType={props.setCurrentProviderType}
                  setNewAccountName={props.setNewAccountName}
                  isLegacyShortcut={props.isLegacyShortcut}
                />
              )}
              <CloudDestinationSelect
                selectedCloudDestination={props.selectedCloudDestination}
                onSelectCloudDestination={props.onSelectCloudDestination}
                providerOptions={props.providerOptions}
                isDisabled={props.isDisabled}
                button={{
                  appearance: "ghost",
                  text: i18n.assetsProvider.getText("pages.ShortcutCreation.cloudSection.viewVerifiedAccLabel"),
                  onClick: () => props.viewVerifiedAccounts(),
                  loading: props.isLoadingTable,
                }}
              />
              <ParentBox>
                <AuthNewAccFrame>
                  {props.providerOptions.map(({ value, label }) => {
                    return (
                      <ProviderItem
                        key={`${value}`}
                        name={value}
                        type={label}
                        onSelect={() => {
                          props.onAddNew(value)
                        }}
                      />
                    )
                  })}
                </AuthNewAccFrame>
              </ParentBox>
            </>
          )}
        </>
      }
    />
  )
}

export default CloudFormSection
