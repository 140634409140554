export const resolveProxyUrl = (stack: number, model: string) => {
  // Replaces blank spaces
  const formattedModel = model.replace(/\s/g, "%20")
  const stacks = {
    0: "pie",
    1: "pie",
    2: "stage",
    3: "prod",
  }
  return `https://reverseproxy-gen2-${stacks[stack]}.ext.hp.com/shortcut/v1/cui?current=shortcut&lang=en&region=US&model=${formattedModel}&expType=workflow&folderID=e1ab8582-666d-442f-b620-0b5f58a7abee`
}
