import { Header } from "@veneer/core"
import styled from "styled-components"

export const MainContainer = styled.div`
  grid-area: "header";
  width: 100% !important;
  min-height: 68px !important;
  max-height: 68px !important;
  -webkit-transform: translate3d(0, 0, 0) !important;
  -moz-transform: translate3d(0, 0, 0) !important;
  -ms-transform: translate3d(0, 0, 0) !important;
  -o-transform: translate3d(0, 0, 0) !important;
  transform: translate3d(0, 0, 0) !important;
  box-shadow: 0px 1px 0px rgba(33, 33, 33, 0.1);
  display: flex;
`

export const LeftContainer = styled.div`
  display: flex !important;
  align-items: center !important;
  padding-left: 15px !important;
`

export const Title = styled.h6`
  padding-left: 12px;
`

export const StyledHeader = styled(Header)`
  padding: 0px !important;
  div {
    &:nth-child(1) {
      justify-self: start;
    }
    &:nth-child(2) {
      justify-self: start;
    }
  }
`
