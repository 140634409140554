import { Modal } from "@veneer/core"
import tokens from "@veneer/tokens"
import styled from "styled-components"

export const StyledModal = styled(Modal)`
  button.vn-modal__close {
    color: rgb(115, 115, 115);
  }
  .vn-modal__close:hover {
    background-color: rgba(88, 88, 88, 0.05);
  }

  div.vn-modal--content {
    width: calc(487px + 48px + 48px);
    padding: 40px;

    @media (max-width: ${tokens.mdMax}) {
      width: calc(487px + 48px + 48px);
    }

    @media (max-width: ${tokens.smMax}) {
      width: 528px;
    }

    @media (max-width: ${tokens.smMin}) {
      width: 90%;
      height: max-content;
    }
  }

  div.vn-modal--body {
    p {
      padding-bottom: 20px;
    }
  }

  div.vn-modal--footer {
    display: flex;
    justify-content: flex-end;
  }
`
