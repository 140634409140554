import { Button, ButtonGroup, IconMinusCircle } from "@veneer/core"
import React from "react"
import { i18n } from "../../assets/I18n"
import {
  CustomDescription,
  CustomTitle,
  CustomTitleText,
  StyledModal,
} from "./styles"

type MaxDestinationModalProps = {
  show: boolean
  onClose: { (): void }
  destinationType: string
}

const MaxDestinationModal = ({
  show,
  onClose,
  destinationType,
}: MaxDestinationModalProps) => {
  return (
    <StyledModal
      id="max-destination-modal-container"
      onClose={onClose}
      show={show}
      footer={
        <ButtonGroup>
          <Button
            id="max-destination-close-button"
            appearance="primary"
            onClick={onClose}
          >
            {i18n.assetsProvider.getText("common.close")}
          </Button>
        </ButtonGroup>
      }
    >
      <CustomTitle id="max-destination-modal-title-container">
        <IconMinusCircle focusable size={46} filled color="colorRed6" />
        <CustomTitleText className="title-small">
          {destinationType === "email"
            ? i18n.assetsProvider.getText("pages.ScanDestinations.toasts.createNewErrorEmail")
            : i18n.assetsProvider.getText("pages.ScanDestinations.toasts.createNewErrorCloud")}
        </CustomTitleText>
      </CustomTitle>
      <CustomDescription className="body-large">
        {destinationType === "email"
          ? i18n.assetsProvider.getText("pages.ScanDestinations.toasts.maxScanDestinationsEmail")
          : i18n.assetsProvider.getText("pages.ScanDestinations.toasts.maxScanDestinationsCloud")}
      </CustomDescription>
    </StyledModal>
  )
}

export default MaxDestinationModal
