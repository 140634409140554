import { JarvisAuthProvider } from "@jarvis/web-http"
import { DeviceShadowApiClient } from "@jarvis/web-stratus-client"
import { useCallback, useState } from "react"
import {
  ScanDestinationStatusResponse,
  ShortcutConfigurationResponse,
  ShortcutDesiredItemRequest,
  ShortcutDesiredItemResponse,
} from "../../data/schemas/deviceShadowApi"
import { resolveProxyUrl } from "../../helpers/resolveProxyUrl"

const useDeviceShadowApi = (
  stack: number,
  authProvider: JarvisAuthProvider
) => {
  const [
    isLoadingShortcutConfiguration,
    setLoadingShortcutConfiguration,
  ] = useState(false)
  const [
    enableScanDestinationStatus,
    setEnableScanDestinationStatus,
  ] = useState<ScanDestinationStatusResponse>()
  const [error, setError] = useState({})
  const [isShadowEmpty, setIsShadowEmpty] = useState<boolean>(false)

  const getScanDestinationStatus = useCallback(
    async (cloudId: string, timeout = 15000) => {
      try {
        const client = new DeviceShadowApiClient(stack, authProvider, timeout)
        const { data } = await client.getShortcutConfiguration({ cloudId })
        setLoadingShortcutConfiguration(true)
        const result = data as ShortcutConfigurationResponse
        const reported = result.state.reported
        if (reported && !reported?.cdmData?.cloudShortcuts) {
          setIsShadowEmpty(true)
        }
        const reportedCloudShortcuts = result.state.reported?.cdmData?.cloudShortcuts?.toLowerCase()
        const desiredCloudShortcuts = result.state.desired?.cdmData?.cloudShortcuts?.toLowerCase()
        const deltaCloudShortcuts = result.state.delta?.cdmData?.cloudShortcuts?.toLowerCase()

        if (
          reportedCloudShortcuts === "true" &&
          !result.state.desired &&
          !result.state.delta
        ) {
          setEnableScanDestinationStatus({
            isEnabled: true,
          } as ScanDestinationStatusResponse)
        } else {
          if (
            reportedCloudShortcuts === "true" &&
            !(desiredCloudShortcuts === "true") &&
            !(deltaCloudShortcuts === "true")
          ) {
            setEnableScanDestinationStatus({
              isEnabled: false,
            } as ScanDestinationStatusResponse)
          } else {
            if (
              reportedCloudShortcuts === "true" ||
              desiredCloudShortcuts === "true"
            ) {
              setEnableScanDestinationStatus({
                isEnabled: true,
              } as ScanDestinationStatusResponse)
            } else {
              setEnableScanDestinationStatus({
                isEnabled: false,
              } as ScanDestinationStatusResponse)
            }
          }
        }

        return result
      } catch (error) {
        setError(error)
        throw error
      } finally {
        setLoadingShortcutConfiguration(false)
      }
    },
    [authProvider, stack]
  )

  const enableScanDestinationConfiguration = useCallback(
    async (cloudId: string, timeout, cloudShortcuts) => {
      try {
        setLoadingShortcutConfiguration(true)
        const requestData: ShortcutConfigurationResponse = {
          state: { desired: { cdmData: { cloudShortcuts: cloudShortcuts } } },
        }

        const client = new DeviceShadowApiClient(stack, authProvider, timeout)
        const { data } = await client.postShortcutConfiguration({
          cloudId,
          data: requestData,
        })

        const result = data as ShortcutConfigurationResponse

        const reportedCloudShortcuts = result.state.reported?.cdmData?.cloudShortcuts.toLowerCase()
        const desiredCloudShortcuts = result.state.desired?.cdmData?.cloudShortcuts.toLowerCase()

        if (
          desiredCloudShortcuts === "true" ||
          reportedCloudShortcuts === "true"
        ) {
          setEnableScanDestinationStatus({
            isEnabled: true,
          } as ScanDestinationStatusResponse)
        } else {
          setEnableScanDestinationStatus({
            isEnabled: false,
          } as ScanDestinationStatusResponse)
        }
      } catch (error) {
        setError(error)
        throw error
      } finally {
        setLoadingShortcutConfiguration(false)
      }
    },
    [authProvider, stack]
  )

  const createShortcutDesiredItem = useCallback(
    async (cloudId: string, model: string, timeout = 15000) => {
      try {
        setLoadingShortcutConfiguration(true)
        const requestData: ShortcutDesiredItemRequest = {
          state: {
            desired: {
              shortcuts: [
                {
                  id: "e1ab8582-666d-442f-b620-0b5f58a7abee",
                  title: "Shortcuts",
                  type: "cloudApp",
                  action: "open",
                  origin: "device",
                  links: [
                    {
                      href: resolveProxyUrl(stack, model),
                      rel: "shortcut",
                    },
                  ],
                },
              ],
            },
          },
        }

        const client = new DeviceShadowApiClient(stack, authProvider, timeout)
        const { data } = await client.postShortcutDesiredItem({
          cloudId,
          data: requestData,
        })

        const result = data as ShortcutDesiredItemResponse
        return result
      } catch (error) {
        setError(error)
        throw error
      } finally {
        setLoadingShortcutConfiguration(false)
      }
    },
    [authProvider, stack]
  )

  return {
    isLoadingShortcutConfiguration,
    enableScanDestinationStatus,
    error,
    getScanDestinationStatus,
    enableScanDestinationConfiguration,
    isShadowEmpty,
    setIsShadowEmpty,
    createShortcutDesiredItem,
  }
}

export default useDeviceShadowApi
