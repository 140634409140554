import {
  IconChevronLeft,
  IconPrinterCheckmark,
  IconPrinterMinus,
  IconPrinterWarning,
  IconShield,
  IconShieldCheckmark,
  IconShieldOff,
} from "@veneer/core"
import React from "react"
import { useHistory, useParams } from "react-router-dom"
import { DeviceProps } from "../../../mfe/types/device"
import { i18n } from "../../assets/I18n"
import {
  Link,
  LinkContainer,
  PrinterDataContainer,
  PrinterDetailsContainer,
  PrinterImageContainer,
  StatusContainer,
  Statuses,
  StatusTitle,
  Subtitle,
  Title,
} from "./styles"

type PrinterWidgetDetailsProps = {
  printer: DeviceProps
  printerStatus: string
  isLoadingStatus: boolean
}

const PrinterWidgetDetails = (props: PrinterWidgetDetailsProps) => {
  const history = useHistory()
  const { base } = useParams()

  enum DeviceState {
    ReadyToPrint = "Ready to Print",
    NeedsAttention = "Needs Attention",
    UnableToPrint = "Unable to Print",
  }

  const deviceState: DeviceState =
    props.printer.status.acceptingJobs === false ||
    props.printer.status.printerStateSeverity === "Error"
      ? DeviceState.UnableToPrint
      : props.printer.status.printerStateSeverity === "Warning"
      ? DeviceState.NeedsAttention
      : DeviceState.ReadyToPrint

  enum SecurityStateStatus {
    NotAvailable = "Not Available",
    Unmonitored = "Unmonitored",
    Monitored = "Monitored",
    NeedsAttention = "NeedsAttention",
    NoDataAvailable = "NoDataAvailable",
  }

  const solutionMetaData = props.printer.solutionMetadata
    ? (Object.values(props.printer.solutionMetadata)[0] as any)
    : null

  const securityStateStatus: SecurityStateStatus =
    solutionMetaData === null
      ? SecurityStateStatus.NoDataAvailable
      : solutionMetaData.active === "false"
      ? SecurityStateStatus.NotAvailable
      : solutionMetaData.state === "disabled"
      ? SecurityStateStatus.Unmonitored
      : solutionMetaData.result === "securityMonitored"
      ? SecurityStateStatus.Monitored
      : solutionMetaData.result === "needsAttention" ||
        solutionMetaData.result === "unknown"
      ? SecurityStateStatus.NeedsAttention
      : SecurityStateStatus.NoDataAvailable

  return (
    <PrinterDetailsContainer id="printer-details-container">
      <PrinterImageContainer>
        <img src={props.printer.images.slice(-1)[0].url} alt="printer" />
      </PrinterImageContainer>
      <PrinterDataContainer id="printer-data-container">
        <Title className="subtitle-large">
          {props.printer.identity.friendlyName
            ? props.printer.identity.friendlyName
            : props.printer.identity.makeAndModel.name}
        </Title>
        <Subtitle className="body">
          {props.printer.identity.makeAndModel.name}
        </Subtitle>
        <Statuses>
          <StatusContainer id="status-container">
            {deviceState === DeviceState.UnableToPrint ? (
              <>
                <IconPrinterMinus color="colorRed7" filled />
                <StatusTitle className="caption">
                  {i18n.assetsProvider.getText(
                    "pages.ScanDestinations.printerDetails.deviceStatus.unableToPrint"
                  )}
                </StatusTitle>
              </>
            ) : deviceState === DeviceState.NeedsAttention ? (
              <>
                <IconPrinterWarning color="colorYellow7" filled />
                <StatusTitle className="caption">
                  {i18n.assetsProvider.getText(
                    "pages.ScanDestinations.printerDetails.deviceStatus.needsAttention"
                  )}
                </StatusTitle>
              </>
            ) : (
              <>
                <IconPrinterCheckmark color="colorGreen6" filled />
                <StatusTitle className="caption">
                  {i18n.assetsProvider.getText(
                    "pages.ScanDestinations.printerDetails.deviceStatus.readytoPrint"
                  )}
                </StatusTitle>
              </>
            )}
          </StatusContainer>
          <StatusContainer id="status-container">
            {securityStateStatus === SecurityStateStatus.NotAvailable ? (
              <>
                <StatusTitle className="caption">
                  {i18n.assetsProvider.getText(
                    "pages.ScanDestinations.printerDetails.securityStateStatus.notAvailable"
                  )}
                </StatusTitle>
              </>
            ) : securityStateStatus === SecurityStateStatus.Unmonitored ? (
              <>
                <IconShieldOff disabled filled />
                <StatusTitle className="caption">
                  {i18n.assetsProvider.getText(
                    "pages.ScanDestinations.printerDetails.securityStateStatus.unMonitored"
                  )}
                </StatusTitle>
              </>
            ) : securityStateStatus === SecurityStateStatus.Monitored ? (
              <>
                <IconShieldCheckmark color="colorGreen6" />
                <StatusTitle className="caption">
                  {i18n.assetsProvider.getText(
                    "pages.ScanDestinations.printerDetails.securityStateStatus.Monitored"
                  )}
                </StatusTitle>
              </>
            ) : securityStateStatus === SecurityStateStatus.NeedsAttention ? (
              <>
                <IconShieldCheckmark color="colorOrange6" filled />
                <StatusTitle className="caption">
                  {i18n.assetsProvider.getText(
                    "pages.ScanDestinations.printerDetails.deviceStatus.needsAttention"
                  )}
                </StatusTitle>
              </>
            ) : (
              <>
                <IconShield color="colorBlack" />
                <StatusTitle className="caption">
                  {i18n.assetsProvider.getText(
                    "pages.ScanDestinations.printerDetails.securityStateStatus.noDataAvailable"
                  )}
                </StatusTitle>
              </>
            )}
          </StatusContainer>
        </Statuses>
        <LinkContainer id="link-container">
          <IconChevronLeft color="colorHpBlue6" />
          <Link
            className="label"
            onClick={() => {
              history.push(`${base}/${props.printer.deviceId}`)
            }}
          >
            {i18n.assetsProvider.getText(
              "pages.ScanDestinations.printerDetails.viewPrinterDetailsText"
            )}
          </Link>
        </LinkContainer>
      </PrinterDataContainer>
    </PrinterDetailsContainer>
  )
}

export default PrinterWidgetDetails
