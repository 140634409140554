import { useEffect, useState } from "react"
import { i18n } from "../../assets/I18n"

const usePINValidation = () => {
  const [pin, setPin] = useState("")
  const [pinConfirmation, setPinConfirmation] = useState("")
  const [pinError, setPinError] = useState("")

  const validatePin = () => {
    return (
      pin &&
      pinConfirmation &&
      pin === pinConfirmation &&
      validateSequence(pin) &&
      validateRepetition(pin)
    )
  }

  useEffect(() => {
    if (
      pin &&
      pinConfirmation &&
      pin.length >= pinConfirmation.length &&
      pin !== pinConfirmation
    ) {
      return setPinError(
        i18n.assetsProvider.getText(
          "pages.ShortcutCreation.validators.unmatchingPinsMessage"
        )
      )
    }

    if (pin && pin.length > 3 && !validateSequence(pin)) {
      return setPinError(
        i18n.assetsProvider.getText(
          "pages.ShortcutCreation.validators.invalidPinSequenceMessage"
        )
      )
    }

    if (pin && pin.length > 3 && !validateRepetition(pin)) {
      return setPinError(
        i18n.assetsProvider.getText(
          "pages.ShortcutCreation.validators.invalidPinRepetitionMessage"
        )
      )
    }

    setPinError("")
  }, [pin, pinConfirmation])

  const validateSequence = (value: string) => {
    // Validates consecutive numbers in ascending and descending order e.g. 1234,4321,345678 etc.
    const numbers = "0123456789"
    const reverseNumbers = "9876543210"
    return (
      numbers.indexOf(String(value)) === -1 &&
      reverseNumbers.indexOf(String(value)) === -1
    )
  }

  const validateRepetition = (value: string) => {
    // Validates single number repetition e.g. 0000,11111,222222, etc.
    return /^(\d)(?!\1+$)\d*$/.test(value)
  }

  const formatInputValue = (value: string) => {
    // This regex removes all non numeric characters
    return value.replace(/\D/g, "")
  }

  return {
    pin,
    setPin,
    pinConfirmation,
    setPinConfirmation,
    pinError,
    validatePin,
    formatInputValue,
  }
}

export default usePINValidation
