import { Button, Select } from "@veneer/core"
import React from "react"
import { i18n } from "../../assets/I18n"
import { Site } from "../../data/schemas/connector"
import { ButtonGroup, SelectContainer, StyledModal, SubTitle } from "./styles"
type ButtonAppearance =
  | "secondary"
  | "primary"
  | "tertiary"
  | "success"
  | "warning"
  | "danger"
type SharePointPathModalProps = {
  show: boolean
  title: string
  subTitle: string
  siteOptions: Site[]
  buttons: {
    primaryLabel: string
    primaryAppearance?: ButtonAppearance
    primaryFunction: { (): void }
    secondaryLabel?: string
    secondaryAppearance?: ButtonAppearance
    secondaryFunction?: { (): void }
    disabled?: boolean
  }
  onClose: { (): void }
  onConfirm: { (site: Site): void }
  onChange: { (option: any): void }
}
const SharePointPathModal = ({
  show,
  title,
  subTitle,
  buttons,
  onClose,
  onChange,
  siteOptions,
}: SharePointPathModalProps) => {
  return (
    <StyledModal
      id="form-modal"
      onClose={onClose}
      show={show}
      title={title}
      footer={
        <ButtonGroup>
          <Button
            id={`${buttons.secondaryLabel}-button`}
            appearance={buttons.secondaryAppearance || "secondary"}
            onClick={buttons.secondaryFunction}
          >
            {buttons.secondaryLabel}
          </Button>
          <Button
            id={`${buttons.secondaryLabel}-button`}
            disabled={buttons.disabled}
            onClick={buttons.primaryFunction}
          >
            {buttons.primaryLabel}
          </Button>
        </ButtonGroup>
      }
    >
      <SubTitle>
        <p>{subTitle}</p>
      </SubTitle>
      <SelectContainer>
        <Select
          options={siteOptions.map((sites) => ({
            label: sites.displayName,
            value: sites.webUrl,
            id: sites.id,
          }))}
          id="select-usage"
          label={i18n.assetsProvider.getText(
            "pages.ShortcutCreation.cloudSection.selectSite"
          )}
          onChange={onChange}
          clearIcon={false}
        />
      </SelectContainer>
    </StyledModal>
  )
}

export default SharePointPathModal
