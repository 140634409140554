export const ldClientValues = {
  0: "5f6b5dfc732fa50ba507048d",
  1: "5f6b5e0cd393fd0a6c84b303",
  2: "5f6b5e1d732fa50ba5070497",
  3: "5f6b5e2292e4c40ae0ad89c3",
}

export function getLDClientSideId(stack: number) {
  return ldClientValues[stack]
}
