import styled from "styled-components"

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px;
  gap: 8px;
  position: absolute;
  width: 98%;
  height: 83%;
  border-bottom-right-radius: 10px;
  margin-top: 10px;
  background: #ffffff;
  opacity: 0.9;
  backdrop-filter: blur(8px);
  flex: none;
  order: 5;
  flex-grow: 0;
  z-index: 5;
`

export const Text = styled.div`
  font-family: "Forma DJR Micro";
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  text-align: center;
  color: #737373;
`
