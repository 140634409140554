import { ProgressIndicator } from "@veneer/core"
import React from "react"
import { i18n } from "../../assets/I18n"
import { LoaderContainer } from "./styles"

type LoaderProps = {
  isQuickSetModalEnabled?: boolean
  showMessage?: boolean
}
const Loader = (props: LoaderProps) => (
  <LoaderContainer
    id="loader-container"
    data-testid="loader-container"
    isQuickSetModalEnabled={props.isQuickSetModalEnabled}
    showMessage={props.showMessage}
  >
    <ProgressIndicator />
    {props.showMessage && (
      <>
        <div className="message">
          {i18n.assetsProvider.getText("pages.ScanDestinations.table.loadingData")}
        </div>
      </>
    )}
  </LoaderContainer>
)

export default Loader
