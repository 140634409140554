import { i18n } from "../assets/I18n"
import { ShortcutItem } from "../data/schemas/shortcut"

export const fileTypeHandler = (shortcut: ShortcutItem) => {
  if (shortcut.smartTask?.smartTaskConfig?.ocr?.ocrOutputFileType === "pdf") {
    return i18n.assetsProvider.getText(
      "pages.ShortcutCreation.settingsSection.typeOptions.searchablePDF"
    )
  } else if (
    shortcut.smartTask?.smartTaskConfig?.ocr?.ocrOutputFileType === "docx"
  ) {
    return i18n.assetsProvider.getText(
      "pages.ShortcutCreation.settingsSection.typeOptions.wordDocument"
    )
  } else if (
    shortcut.smartTask?.smartTaskConfig?.ocr?.ocrOutputFileType === "txt"
  ) {
    return i18n.assetsProvider.getText(
      "pages.ShortcutCreation.settingsSection.typeOptions.plainText"
    )
  } else if (shortcut?.smartTask?.fileType === "pdf") {
    return i18n.assetsProvider.getText("pages.ShortcutCreation.settingsSection.typeOptions.standardPDFNew")
  } else if (shortcut?.smartTask?.fileType === "jpeg") {
    return i18n.assetsProvider.getText(
      "pages.ShortcutCreation.settingsSection.typeOptions.image"
    )
  }
  return shortcut?.smartTask?.fileType
}
