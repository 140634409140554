import { JarvisAuthProvider } from "@jarvis/web-http"
import { InlineNotification, Toggle, useToast } from "@veneer/core"
import { useFlags } from "launchdarkly-react-client-sdk"
import React, { useEffect, useState } from "react"
import { i18n } from "../../assets/I18n"
import useTenantApi from "../../hooks/api/useTenantApi"
import DisableScanDestinationsModal from "../DisableScanDestinationsModal"
import { ShortcutDataContainer } from "./styles"

type PrinterWidgetShortcutInfoProps = {
  emailCount: string
  saveCount: string
  deviceUuid: string
  cloudId: string
  stack: number
  authProvider: JarvisAuthProvider
}

const PrinterWidgetShortcutInfo = (props: PrinterWidgetShortcutInfoProps) => {
  const { getDeviceSolutionStatus, createDeviceSolutionStatus } = useTenantApi(
    props.stack,
    props.authProvider
  )

  const { addToast } = useToast()

  const [
    showDisableScanDestinationsModal,
    setShowDisableScanDestinationsModal,
  ] = useState(false)

  const [toggleStatus, setToggleStatus] = useState(false)

  const [disableToggle, setDisableToggle] = useState(false)

  const fetchData = async () => {
    try {
      const deviceSulutionStatus = await getDeviceSolutionStatus(
        props.deviceUuid
      )
      setToggleStatus(deviceSulutionStatus.isEnabled)
    } catch (error) {
      if (error.response?.status === 404) {
        try {
          await createDeviceSolutionStatus(props.deviceUuid, {
            enable: true,
          })
          setToggleStatus(true)
        } catch (error) {
          console.error("Error while creating device solution status", error)
        }
      } else {
        addToast({
          id: "smb-scan-destinations-create-solution-status-error",
          type: "negative",
          text: i18n.assetsProvider.getText(
            "pages.ScanDestinations.toasts.somethingWentWrong"
          ),
        })
      }
    }
  }

  const handleToggleChange = async () => {
    setToggleStatus(!toggleStatus)
    setDisableToggle(true)
    try {
      await createDeviceSolutionStatus(props.deviceUuid, {
        enable: !toggleStatus,
      })
    } catch (error) {
      setToggleStatus(toggleStatus)
      addToast({
        id: `smb-scan-destinations-mount-account-name-error`,
        type: "negative",
        text: i18n.assetsProvider.getText(
          "pages.ScanDestinations.toasts.somethingWentWrong"
        ),
      })
    }
    setDisableToggle(false)
  }

  const { smbAllowControlPanelShortcutsCreation } = useFlags()

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <ShortcutDataContainer id="shortcut-data-container">
        <InlineNotification
          action={{
            action: () => setShowDisableScanDestinationsModal(true),
            label: i18n.assetsProvider.getText("common.disable"),
          }}
          closeButton={false}
          title={
            <>
              <span>
                {" "}
                {i18n.assetsProvider.getText(
                  "pages.ScanDestinations.printerDetails.printerDetailsTitle"
                )}{" "}
              </span>
              <span style={{ color: "green" }}>
                {i18n.assetsProvider.getText("common.enabled")}
              </span>
            </>
          }
          type="positive"
          description={i18n.assetsProvider.getText(
            "pages.ScanDestinations.printerDetails.enabledScanDestinationsCard"
          )}
        />
        {smbAllowControlPanelShortcutsCreation && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "-webkit-fill-available",
              alignSelf: "normal",
            }}
            id="toggle-container"
          >
            <span>
              {i18n.assetsProvider.getText(
                "pages.ScanDestinations.printerDetails.toggleControlPanel"
              )}
            </span>
            <Toggle
              id="toggle_id"
              on={toggleStatus}
              onChange={handleToggleChange}
              disabled={disableToggle}
            />
          </div>
        )}
      </ShortcutDataContainer>
      <DisableScanDestinationsModal
        stack={props.stack}
        authProvider={props.authProvider}
        cloudId={props.cloudId}
        isOpen={showDisableScanDestinationsModal}
        onClose={() => {
          setShowDisableScanDestinationsModal(false)
        }}
      />
    </>
  )
}

export default PrinterWidgetShortcutInfo
