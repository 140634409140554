import { UserMgtSvcClient } from "@jarvis/web-stratus-client"
import { AxiosResponse } from "axios"

export default class UserMgtSvcV3ClientExt extends UserMgtSvcClient {
  apiVersion = "3"

  static getCurrentActiveUserV3Schema(): Record<string, unknown> {
    return {
      $schema: "http://json-schema.org/schema#",
      type: "object",
      additionalProperties: true,
      properties: {
        resourceId: {
          type: "string",
        },
        email: {
          type: "object",
          required: ["value", "verified"],
          properties: {
            value: {
              type: "string",
            },
            verified: {
              type: "boolean",
            },
          },
        },
        familyName: {
          type: "string",
        },
        givenName: {
          type: "string",
        },
      },
    }
  }

  async getCurrentActiveUser(): Promise<AxiosResponse> {
    return this.jarvisWebHttpInstance.get({
      url: `${this.usersEndpoint}/me`,
      validationSchema: UserMgtSvcV3ClientExt.getCurrentActiveUserV3Schema(),
    })
  }
}
