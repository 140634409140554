import {
  Card,
  ContextualMenu,
  IconChevronRight,
  IconWarning,
  Table,
} from "@veneer/core"
import tokens from "@veneer/tokens"
import styled from "styled-components"

export const CapabilitiesLoader = styled.div`
  padding: 32px 10px 32px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const CloudFormTable = styled(Table)`
  margin: 0 16px;
  padding-bottom: 24px;
  -webkit-box-sizing: border-box !important;
  -moz-box-sizing: border-box !important;
  box-sizing: border-box !important;
  width: auto !important;
  min-width: 200px !important;
  border-bottom: 1px solid ${tokens.colorGray2};
  display: flex;
  align-items: center;
  #type {
    text-transform: capitalize;
  }
  .css-zq7vkh {
    display: table-cell;
    vertical-align: middle;
  }
`

export const ContextualMenuTableButton = styled(ContextualMenu)`
  & li[aria-disabled="true"] {
    display: none !important;
  }
`

export const CloudFormSelectContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px 4px 16px 16px;
  & button:hover {
    color: #0278ab;
    background-color: white;
  }

  @media (max-width: ${tokens.smMin}) {
    flex-direction: column;
  }
`

export const ExistingCloudTitle = styled.p`
  padding: 16px;
  font-size: 18px;
  line-height: 24px;
`

export const CloudSelectLabel = styled.p`
  -webkit-box-sizing: border-box !important;
  -moz-box-sizing: border-box !important;
  box-sizing: border-box !important;
  min-width: 200px !important;
  padding-right: 6px;
  font-size: 18px !important;
  line-height: 24px;
  & img {
    margin-right: 10px;
  }
  & span {
    margin-top: 2px;
  }

  @media (max-width: ${tokens.smMin}) {
    align-self: flex-start;
    margin-bottom: 12px;
  }
`

export const CloudSelectDescription = styled.p`
  padding-left: 16px;
  padding-bottom: 16px;
  font-size: 14px !important;
  line-height: 20px !important;

  @media (max-width: ${tokens.smMin}) {
    padding-left: 0px;
  }
`

export const MainContainer = styled(Card)`
  display: grid;
  grid-template-rows: auto auto;
  background-color: #fafafa !important;
  border: none !important;
`

export const ProviderItemContainer = styled.div`
  display: grid;
  grid-template-columns: 15% 70% 15%;
  cursor: pointer;
  width: 96%;
  height: 68px;
  margin-bottom: 10px;
  border-radius: 16px;
  background: var(--Light-Background-Container, #fff);
  box-shadow: 0px 1px 4px 1px rgba(33, 33, 33, 0.2);
  transition: box-shadow 0.3s;
  &:hover {
    box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
  }
  @media (max-width: ${tokens.mdMax}) {
    margin: 0;
  }

  @media (max-width: ${tokens.smMin}) {
    display: flex;
    width: 100%;
    height: auto;
    padding: 16px 0;
  }

  @media (max-width: 270px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`

export const ProviderItemImage = styled.div`
  align-self: center;
  display: flex;
  margin-left: 14px;

  @media (max-width: ${tokens.smMin}) {
    img {
      width: 36px !important;
    }
  }
`

export const ProviderItemHeader = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  justify-content: start;
  overflow: hidden;
  width: 100%;
  white-space: nowrap;
  align-items: center;
  margin-left: 8px;

  @media (max-width: ${tokens.mdMin}) {
    grid-template-columns: 65% 35%;
    gap: 8px;

    div {
      margin-left: 8px !important;
    }
  }

  @media (max-width: ${tokens.smMin}) {
    display: flex;
    width: 100%;
  }

  @media (max-width: 270px) {
    width: auto;
  }
`

export const ProviderItemName = styled.p`
  color: ${tokens.colorGray10};
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 10px;
  margin-left: 12px;
  font-size: 18px !important;
  line-height: 24px !important;

  @media (max-width: ${tokens.smMin}) {
    margin: 0;
    margin-right: 5px;
  }

  @media (max-width: 390px) {
    white-space: break-spaces !important;
  }

  @media (max-width: 300px) {
    font-size: 12px !important;
  }

  @media (max-width: 270px) {
    text-align: center;
  }
`

export const StyledIconChevronRight = styled(IconChevronRight)`
  justify-content: flex-end;
  margin: 0 16px;
`

export const ChevronContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;

  @media (max-width: ${tokens.smMin}) {
    width: auto;
  }
`

export const ParentBox = styled.div`
  &::after {
    display: flex;
    content: "";
  }
`

export const AuthNewAccFrame = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  padding-left: 16px;

  @media (max-width: ${tokens.mdMax}) {
    overflow: hidden;
    height: auto;
    gap: 16px;
    padding: 10px;
  }

  @media (max-width: ${tokens.smMin}) {
    grid-template-columns: 100%;
    padding: 2px;
  }
`

export const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  float: right;
  svg {
    cursor: pointer !important;
    margin-right: 12px !important;
  }
`

export const ProviderInfoContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

export const ProviderInfoTypeEmailContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

export const ProviderText = styled.div`
  color: ${tokens.colorGray10};
  overflow: hidden;
  overflow-wrap: anywhere;
  text-overflow: ellipsis;
  padding-top: 2px;
`

export const ProviderFailedText = styled.div`
  color: ${tokens.colorRed6};
  overflow: hidden;
  overflow-wrap: anywhere;
  text-overflow: ellipsis;
  padding-top: 2px;
`

export const ProviderFolderIconContainer = styled.div`
  display: flex;
  padding-right: 6px;
`

export const ProviderSiteIconContainer = styled.div`
  display: flex;
  padding-right: 6px;
`

export const ErrorContainer = styled(Card)`
  display: flex;
  justify-content: center;
  padding: 40px 10px 48px;
  justify-content: center !important;
`
export const ProviderEmailText = styled.div`
  margin-top: 2px;
  margin-left: 2px;
  color: ${tokens.colorGray10};
`

export const ReauthorizeText = styled.div`
  cursor: pointer;
  color: ${tokens.colorRed6} !important;
`

export const StyledIconWarning = styled(IconWarning)`
  margin-right: 11px;
`

export const AuthenticationFailedContainer = styled.div`
  color: ${tokens.colorGray10};
  margin-top: -14px;
  margin-left: 20px;
`

export const InvalidFolder = styled.div`
  width: auto;
  margin-left: 5px;
  color: #adadad !important;
  cursor: default;
  display: -webkit-box;

  svg {
    margin-left: 4px;
    margin-bottom: -7px;
  }
`
