import { Modal } from "@veneer/core"
import tokens from "@veneer/tokens"
import styled from "styled-components"

export const DisconnectAccModal = styled(Modal)`
  z-index: 1400 !important;
  div:nth-child(2) {
    display: grid;
  }
  div.vn-modal--content {
    width: 496px;
    padding: 40px !important;
    @media (max-width: ${tokens.xsMax}) {
      width: auto !important;
    }
  }
  button {
    color: ${tokens.colorGray6};
  }
`

export const CustomTitleText = styled.div`
  color: ${tokens.colorGray10};
  margin-bottom: 20px;
  font-size: 32px !important;
  line-height: 40px !important;
`

export const CustomDescription = styled.p`
  color: ${tokens.colorGray10};
  font-size: 16px !important;
`
