import { FolderNameResponse } from "../data/schemas/folders"

export const resolveFolderIds = (folderNames: FolderNameResponse) => {
  const listOfInvalidFolderIds: string[] = []

  if (folderNames.folderInfoList)
    for (const folderName of folderNames.folderInfoList) {
      if (folderName.folderId && !folderName.isValid) {
        listOfInvalidFolderIds.push(folderName.folderId)
      }
    }

  return listOfInvalidFolderIds
}
