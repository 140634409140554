import React from "react"

const IconFilePath = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.25 3C4.55964 3 4 3.55964 4 4.25V6.75C4 7.44036 4.55964 8 5.25 8H6V17.75C6 18.4404 6.55964 19 7.25 19H15V19.75C15 20.4404 15.5596 21 16.25 21H18.75C19.4404 21 20 20.4404 20 19.75V17.25C20 16.5596 19.4404 16 18.75 16H16.25C15.5596 16 15 16.5596 15 17.25V18H7.25C7.11193 18 7 17.8881 7 17.75V12.5H15V13.25C15 13.9404 15.5596 14.5 16.25 14.5H18.75C19.4404 14.5 20 13.9404 20 13.25V10.75C20 10.0596 19.4404 9.5 18.75 9.5H16.25C15.5596 9.5 15 10.0596 15 10.75V11.5H7V8H7.75C8.44036 8 9 7.44036 9 6.75V4.25C9 3.55964 8.44036 3 7.75 3H5.25ZM5 4.25C5 4.11193 5.11193 4 5.25 4H7.75C7.88807 4 8 4.11193 8 4.25V6.75C8 6.88807 7.88807 7 7.75 7H5.25C5.11193 7 5 6.88807 5 6.75V4.25ZM16.25 17C16.1119 17 16 17.1119 16 17.25V19.75C16 19.8881 16.1119 20 16.25 20H18.75C18.8881 20 19 19.8881 19 19.75V17.25C19 17.1119 18.8881 17 18.75 17H16.25ZM16 10.75C16 10.6119 16.1119 10.5 16.25 10.5H18.75C18.8881 10.5 19 10.6119 19 10.75V13.25C19 13.3881 18.8881 13.5 18.75 13.5H16.25C16.1119 13.5 16 13.3881 16 13.25V10.75Z"
        fill="#212121"
      />
    </svg>
  )
}

export default IconFilePath
