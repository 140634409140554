import { ShortcutConfigurationResponse } from "../data/schemas/deviceShadowApi"

export const resolveDeviceShadowStatus = (
  result: ShortcutConfigurationResponse
) => {
  const reportedCloudShortcuts = result.state.reported?.cdmData?.cloudShortcuts?.toLowerCase()
  const desiredCloudShortcuts = result.state.desired?.cdmData?.cloudShortcuts?.toLowerCase()
  const deltaCloudShortcuts = result.state.delta?.cdmData?.cloudShortcuts?.toLowerCase()

  if (
    reportedCloudShortcuts === "true" &&
    !result.state.desired &&
    !result.state.delta
  ) {
    return true
  } else {
    if (
      reportedCloudShortcuts === "true" &&
      !(desiredCloudShortcuts === "true") &&
      !(deltaCloudShortcuts === "true")
    ) {
      return false
    } else {
      if (
        reportedCloudShortcuts === "true" ||
        desiredCloudShortcuts === "true"
      ) {
        return true
      } else {
        return false
      }
    }
  }
}
