import { CustomImage } from "@veneer/core"
import React from "react"
import Images from "../../assets/images"

export const providers = {
  onedrive: {
    name: "OneDrive",
    icon: Images.oneDrive,
  },
  googledrive: {
    name: "Google Drive",
    icon: Images.googleDrive,
  },
  dropbox: {
    name: "Dropbox",
    icon: Images.dropBox,
  },
  evernote: {
    name: "Evernote",
    icon: Images.evernote,
  },
  quickbooksonline: {
    name: "Quickbooks",
    icon: Images.quickBooks,
  },
  box: {
    name: "Box",
    icon: Images.box,
  },
  onedrivebusiness: {
    name: "OneDrive For Business",
    icon: Images.oneDrive,
  },
  sharepoint: {
    name: "Sharepoint",
    icon: Images.sharepoint,
  },
  // Authenticated providers
  authonedrive: {
    name: "OneDrive",
    icon: Images.authOneDrive,
  },
  authgoogledrive: {
    name: "Google Drive",
    icon: Images.authGoogleDrive,
  },
  authdropbox: {
    name: "Dropbox",
    icon: Images.authDropbox,
  },
  authevernote: {
    name: "Evernote",
    icon: Images.authEvernote,
  },
  authquickbooksonline: {
    name: "Quickbooks",
    icon: Images.authQuickbooks,
  },
  authbox: {
    name: "Box",
    icon: Images.authBox,
  },
  authonedrivebusiness: {
    name: "OneDrive For Business",
    icon: Images.authOneDriveBusiness,
  },
  authsharepoint: {
    name: "Sharepoint",
    icon: Images.authSharepoint,
  },
}

export type ProvidersOptions = {
  value:
    | "onedrive"
    | "googledrive"
    | "dropbox"
    | "evernote"
    | "quickbooksonline"
    | "box"
    | "onedrivebusiness"
    | "sharepoint"
    | "authonedrive"
    | "authgoogledrive"
    | "authdropbox"
    | "authevernote"
    | "authquickbooksonline"
    | "authbox"
    | "authonedrivebusiness"
    | "authsharepoint"
  label: string
  icon?: React.ReactNode
}

const baseProviders: ProvidersOptions[] = [
  {
    value: "onedrive",
    label: providers.onedrive.name,
    icon: <CustomImage src={providers.onedrive.icon.toString()} />,
  },
  {
    value: "googledrive",
    label: providers.googledrive.name,
    icon: <CustomImage src={providers.googledrive.icon.toString()} />,
  },
  {
    value: "evernote",
    label: providers.evernote.name,
    icon: <CustomImage src={providers.evernote.icon.toString()} />,
  },
  {
    value: "dropbox",
    label: providers.dropbox.name,
    icon: <CustomImage src={providers.dropbox.icon.toString()} />,
  },
  {
    value: "box",
    label: providers.box.name,
    icon: <CustomImage src={providers.box.icon.toString()} />,
  },
  {
    value: "quickbooksonline",
    label: providers.quickbooksonline.name,
    icon: <CustomImage src={providers.quickbooksonline.icon.toString()} />,
  },
]

const entitledProviders: ProvidersOptions[] = [
  {
    value: "onedrivebusiness",
    label: providers.onedrivebusiness.name,
    icon: <CustomImage src={providers.onedrivebusiness.icon.toString()} />,
  },
  {
    value: "sharepoint",
    label: providers.sharepoint.name,
    icon: <CustomImage src={providers.sharepoint.icon.toString()} />,
  },
]

export const getProvidersList = (isEntitled: boolean) => {
  if (isEntitled) {
    return [...entitledProviders, ...baseProviders]
  }
  return baseProviders
}
